@import '../../../../../styles/sharedStyle.index.scss';

.stage-container {
  .add-task-input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    @include themed(background-color, backgroundBrandLowContrast);
    @include themed(border, transparent);
    @include fontBody;
    padding: 0 37px;
    padding-bottom: 1px;
    @include themed(color, textPrimary);

    &:focus {
      outline: none;
    }
  }
}
