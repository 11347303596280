@import '../../../../../styles/sharedStyle.index.scss';

$workBlockTaskItemHeight: $miniTaskHeight;
$workBlockTaskListGap: 8px;
$workBlockTaskListMarginTop: 10px;
$workBlockContainerPadding: 8px;
$workBlockMoreTaskBtnHeight: 10px;

// export the SASS variable to be used in a .tsx file
:export {
  workBlockTaskItemHeight: $workBlockTaskItemHeight;
  workBlockTaskListGap: $workBlockTaskListGap;
  workBlockContainerPadding: $workBlockContainerPadding;
  workBlockMoreTaskBtnHeight: $workBlockMoreTaskBtnHeight;
  workBlockTaskListMarginTop: $workBlockTaskListMarginTop;
}
