.phone-verification-flow {
  overflow-y: auto;

  .app-phone-controllers-container {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    column-gap: 10px;
    margin-bottom: 60px;

    .error {
      max-width: 240px;
      bottom: -36px;
    }
  }
}
