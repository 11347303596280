@import '../../../../../../../styles/sharedStyle.index.scss';

.task-edit-overlay-type-details-container {
  width: 100%;
  @include displayFlex(space-between, center);
  margin-bottom: 22px;
  cursor: pointer;

  &--disabled,
  &--opacity {
    opacity: 0.5;
  }

  &--disabled {
    pointer-events: none;
  }

  .task-edit-overlay-type-title-and-icon-container {
    @include displayFlex(flex-start, center);
    gap: 6px;
    @include themed(color, textPrimary);
  }

  .task-edit-overlay-type-data {
    margin-right: 8px;
    @include fontBody;
    @include themed(color, textPrimary);
  }
}

@include mobileView {
  .task-edit-overlay-type-details-container {
    margin-bottom: 35px;

    .task-edit-overlay-type-title-and-icon-container {
      font-size: 16px;
      letter-spacing: 0.16px;
    }
  }
}

@include desktopView {
  .task-edit-overlay-type-details-container {
    margin-bottom: 22px;

    .task-edit-overlay-type-title-and-icon-container {
      font-size: 16px;
      letter-spacing: 0.16px;
    }
  }
}
