@import '../../styles/sharedStyle.index.scss';
@import './ProductTour.module.scss';

$cardPadding: 20px;
$startButtonHeight: 37.5px;

.product-tour-container {
  @include themed(background-color, backgroundTransparent);
  height: 100vh;
  height: calc(var(--availableViewportHeight) * 100);
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $appOverlayComponentZIndex;
  .product-tour-card {
    height: calc($productTourCardHeight * 1px);
    width: calc($productTourCardWidth * 1px);
    @include boxShadow();
    @include themed(background-color, backgroundPrimary);
    position: absolute;
    border-radius: 15px;
    @include displayFlex(space-between, flex-start, column);
    padding: $cardPadding;

    section {
      width: 100%;
    }
    .actions-steps-container {
      display: flex;
      gap: 6px;
    }
    .product-tour-button {
      &--skip {
        @include transparencyBtn;
      }
      &--next,
      &--prev {
        width: 30px;
        height: 24px;
        padding: 8px 0;
        border-radius: 8px;
      }
      &--next,
      &--prev,
      &--start-and-done {
        @include themed(background-color, buttonSecondary);
      }
      &--start-and-done {
        width: 101px;
        height: $startButtonHeight;
        @include fontFootnote;
        letter-spacing: .12px;
        @include themed(color, textPrimary);
      }
      &--skip {
        @include fontFootnote;
        @include themed(color, textPrimary);
        text-decoration-line: underline;
      }
      &--hidden {
        visibility: hidden;
      }
    }

    .title-container {
      width: 100%;
      @include displayFlex(space-between, center);
      margin-bottom: 17px;
      
      .product-tour-card-title {
        @include fontSmallTitle(-.24px);
        @include themed(color, brandPrimary);
        margin: 0;
        max-width: 78%;
      }
    }

    .product-tour-card-paragraph, .product-tour-card-list, .product-tour-card-bullet-title {
      @include fontBody;
      @include themed(color, textPrimary);
    }
    .product-tour-card-bullet-title {
      font-weight: 600;
    }

    .product-tour-card-list {
      padding: 0;
      padding-left: 10px;
      margin: 0;
      list-style: none;

      li {
        margin-top: 4px;
        b,
        p {
          display: inline;
        }
        &::before {
          content: '';
          display: inline-block;
          width: 7px;
          height: 7px;
          @include themed(color, backgroundBrandInvert); /* bullet color */
          border-radius: 50%;
          margin-right: 10px; /* space between bullet and text */
          margin-bottom: 2px;
        }
      }
    }

    .actions-container {
      width: 100%;
      min-height: $startButtonHeight;
      @include displayFlex(space-between, center);

      .product-tour-button {
        &--prev {
          transform: rotate(180deg);
        }
      }
    }

    .chevron {
      position: absolute;
      clip-path: polygon(50% 0%, 0% 60%, 100% 60%);
      width: 20px;
      height: 15px;
      @include themed(background-color, backgroundPrimary);

      &.left {
        transform: rotate(-90deg);
      }
      &.right {
        transform: rotate(90deg);
      }
      &.down {
        transform: rotate(180deg);
      }
    }

    &--last-screen {
      .product-tour-card-skip {
        display: none;
      }

      .actions-container .product-tour-button--next {
        width: 80px;
        height: 30px;
        display: block;
        border-radius: 5px;
        padding: 0;
        padding-bottom: 3px;
      }
    }

    &--no-target {
      .chevron {
        display: none;
      }
    }
  }
}

@include desktopView {
  .product-tour-container {
    .product-tour-card--no-target {
      top: 30px !important;
      left: $productTourDesktopLeftSpacingFromWindowPX !important;
    }
  }
}

@include mobileView {
  .product-tour-container {
    .product-tour-card--no-target {
      top: unset !important;
      bottom: $productTourMobileBottomSpacingFromWindowPX !important;
      left: 50% !important;
      transform: translate(-50%, 0%) !important;
    }
  }
}
