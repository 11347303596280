@import '../../../../../../../styles/sharedStyle.index.scss';
@import '../singleTaskItemContainer.module.scss';

.task-action-button {
  @include displayFlex(center, center);
  @include themed(border, none);
  @include themed(color, textInvert);
  @include fontCaption;

  &--due {
    @include themed(background-color, buttonSecondary);
    @include themed(color, textBrandHighContrast);
  }

  &--schedule {
    @include themed(background-color, brandPrimary);
  }

  &--delete {
    @include themed(background-color, colorStateError);
  }

  &--timer {
    @include themed(background-color, brandTertiary);
  }
}

@include desktopView {
  .task-action-button {
    border-radius: 4px;
    margin-right: 5px;
    padding: 0;
    width: 64px;
    height: 30px;
  }
}

// mobile view
@mixin taskActionInMobileAndTablets {
  width: calc($singleTaskActionButtonWidth * 1px);
  height: 24px;
  margin: 0;
  padding: 0;
  padding-bottom: 1px;
  border-radius: 4px;
  @include themed(border, none);

  &.task-action-button {
    &--delete {
      margin-right: 1px;
    }
  }
}

@include mobileView {
  .task-action-button {
    @include taskActionInMobileAndTablets;
  }
}

@include TabletsAndIPadsView {
  .task-action-button {
    @include taskActionInMobileAndTablets;
  }
}

.task-action-button--mobile-view {
  @include taskActionInMobileAndTablets;
}
