@import '../../../../product-tour/ProductTour.module.scss';
@import './StagePlanner.scss';

@include desktopView {
  .product-tour-container.mini-plan-tour {
    .product-tour-card--no-target {
      top: unset !important;
      bottom: calc($chatAndStageContainerPaddingTopBottom + $planContainersPadding) !important;
      left: $planTourDesktopLeftSpacingFromWindowPX !important;

      &.plan-tour-card--opened-planner {
        left: unset !important;
        right: calc(
          $chatAndStageContainerPaddingRightLeft + $planContainersPadding + 14px
        ) !important;
        bottom: calc(
          $chatAndStageContainerPaddingTopBottom + $planContainersPadding + 14px
        ) !important;
      }
    }
  }
}

@include mobileView {
  .product-tour-container.mini-plan-tour {
    .product-tour-card--no-target.plan-tour-card--opened-planner {
      top: 20% !important;
    }
  }
}
