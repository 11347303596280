@import '../../../styles/colors.scss';
@import '../../../styles/themes/theme-mixins.scss';

.app-select-container,
.app-select-container select {
  position: relative;
  display: inline-block;
  width: 130px;
  height: 39px;
  border-radius: 8px;
  @include themed(background-color, backgroundPrimary);
  cursor: pointer;

  &--open {
    border-radius: 8px 8px 0px 0px;
  }

  select,
  option {
    text-align: center;
    text-align-last: center;
    @include themed(color, textPrimary);
  }

  select:focus {
    @include themed(border, none, null !important);
    outline: none !important;
  }
}

.app-select-element {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 16px;
  appearance: none; // Remove default arrow
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  text-align: center;
  border-radius: 8px;
  @include themed(background-color, backgroundTransparent);
  @include themed(border, none);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.app-custom-options {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  right: 0;
  z-index: 1000;
  @include themed(background-color, backgroundPrimary);
  @include themed(border, borderInput, 1px solid);
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 8px;
  display: none;
  padding: 12px;
  transform: translateZ(0);
  will-change: transform;
  transform: perspective(1px);

  &--open {
    display: block;
  }
}

.app-custom-option {
  cursor: pointer;
  &:first-child {
    padding-bottom: 12px;
  }
  &:not(:first-child) {
    padding-top: 12px;
    padding-bottom: 12px;
    @include themed(border-top, borderSecondary, 1px solid);
  }
}

.app-select-arrow {
  position: absolute;
  right: 7px;
  top: 14px;
  z-index: 1;
}

.app-select-container .app-custom-select,
.app-select-container .app-select-element {
  width: 100%;
}

.app-select-element-open::after,
.app-custom-select-open::after {
  border-top: none;
  border-bottom: 5px solid black;
}