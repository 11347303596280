@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/sharedStyle.index.scss';

.calendar-sync-popover-container {
  padding: 20px;
  min-height: 157px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 22px;

  .title-and-back-section {
    @include displayFlex(flex-start, center);
  }

  .calendar-sync-overlay-header-back {
    @include transparencyBtn;
    margin-right: 8px;
  }

  .calendar-sync-overlay-header-text {
    @include fontBody;
    @include themed(color, textPrimary);
    margin: auto;
  }
}

.calendar-sync-popover-title {
  @include themed(color, textPrimary);
  @include fontBody;
  margin: 16px auto;
}

.calendar-sync-popover-buttons,
.calendar-sync-type-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button:not(.calendar-sync-popover-ical-button) {
    justify-content: flex-start;
  }

  button {
    @include fontBody;
    width: 200px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding-left: 18px;
    padding-right: 0;
    white-space: nowrap;
    @include themed(border, none);
    @include themed(color, textContrast);
    @include themed(background-color, buttonThirdParty);
    margin-bottom: 16px;

    &.calendar-sync-popover-sync-option {
      border-radius: 8px;
      @include themed(border, none);
      padding: 0;
      .calendar-sync-button-content-container {
        width: 100%;
      }
      .title-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        img {
          margin-right: 5px; 
        }
      }
      .title {
        @include fontBody;
      }
      .subTitle {
        @include fontCaption;
        @include themed(color, textSecondary);
        width: 100%;
        text-align: center;
      }
    }

    &.calendar-sync-popover-ical-button {
      padding-left: 0;
    }

    img, svg {
      margin-right: 13px;
    }
  }
}

.calendar-sync-type-buttons {
  padding-top: 30px;

  .title {
    display: flex;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
    cursor: pointer;
    margin-left: 0;
  }

  p {
    font-size: 10px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
  }

  button {
    width: 288px;
    height: 60px;
    @include themed(background-color, backgroundBrandMediumContrast);
    @include themed(color, textPrimary);
  }
}

.calendar-sync-google-button,
.calendar-sync-outlook-synced,
.calendar-sync-apple-synced,
.calendar-sync-ical-synced {
  @include themed(background-color, buttonThirdParty);
  @include themed(color, textContrast);
  width: 105px;
  height: 40px;
  border-radius: 5px;
  @include themed(border, none);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  pointer-events: none;
  padding: 0;

  img {
    margin-right: 13px;
  }
}

.calendar-sync-outlook-synced,
.calendar-sync-apple-synced,
.calendar-sync-ical-synced {

  img, svg {
    margin-right: 8px;
  }
}

.calendar-sync-flex {
  width: inherit !important;
}

.calendar-sync-remove {
  margin-right: 15px;
  font-family: 'Lato' !important;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  @include themed(border, transparent);
  @include themed(color, colorStateError);
  @include themed(background-color, backgroundTransparent);

  img {
    margin-right: 5px;
  }
}