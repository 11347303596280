@import '../../../../../../styles/sharedStyle.index.scss';

.tasks-filter-item {
  @include themed(background-color, backgroundBrandMediumContrast);
  @include themed(color, textPrimary);
  @include displayFlex(center, center);
  padding: 0 8px;
  height: 24px;
  @include fontFootnote;
  gap: 4px;
  border-radius: 8px;

  .remove-filter-button {
    @include transparencyBtn;
  }
}