@import '../../../../../styles/sharedStyle.index.scss';

.login-with-phone-page {
  .login-with-phone-content-container {
    height: 100%;
    overflow-y: auto;

    .login-with-phone-instructions {
      @include textTypography(16px, 400, 0.16px);

      &.login-with-phone-resend-instructions {
        @include textTypography(12px, 400, 0.12px);
        span {
          text-decoration: underline;
        }
      }
    }

    .login-with-phone-title {
      @include textTypography(36px, 500, 0.36px);
      margin-bottom: 35px;
    }
  }
  &--view-type-2 {
    text-align: center;
    .login-with-phone-content-container {
      row-gap: 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .login-with-phone-page {
    .login-with-phone-title {
      font-size: 24px;
    }

    .login-with-phone-instructions {
      margin-bottom: 4vh;
    }

    &--view-type-2 {
      .login-with-phone-instructions:first-of-type {
        margin-bottom: 45px !important;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .login-with-phone-page {
    .login-with-phone-instructions {
      margin-bottom: convertPxToVhVw(50, 'vh');
    }

    &--view-type-2 {
      .login-with-phone-instructions:first-of-type {
        margin-bottom: 81px;
      }
    }
  }
}
