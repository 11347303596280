@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.content-frame-wrapper {
  margin: 0 auto;
  @include themed(background-color, backgroundPrimary);
  border-radius: 8px;
  text-align: center;
  @include boxShadow();
  overflow: hidden;
  position: relative;
  z-index: 9999;

  .lottie-hamburger-animation {
    width: 93px;
    margin-right: -23px;
    margin-top: 4px;
  }

  .close-popup-icon {
    width: fit-content;
    height: fit-content;
  }

  &:has(.stage-container .details-dialog-animation-container.endSlideAnimation) {
    @include themed(background-color, backgroundPrimary, null, !important);
  }
}

@media only screen and (max-width: 768px) {
  .content-frame-wrapper {
    width: 88%;
    max-width: 349px;
    height: 83%;
    @include themed(border, borderPrimary, 1px solid);
    padding: 2.5vh 3.5vw 3.5vh 3.5vw;
  }
}

@media only screen and (min-width: 769px) {
  div:has(> .content-frame-wrapper) {
    @include displayFlex(center, center);
  }

  .content-frame-wrapper {
    width: 60%;
    height: 92%;
    border-radius: 15px;
    padding: 5vh 3vw;
    @include themed(border, borderPrimary, 1px solid);

    .lottie-hamburger-animation {
      margin-right: -40px;
      margin-top: 2px;
    }
  }
}

@include mobileView {
  .app-main .content-frame-wrapper {
    @include themed(background-color, backgroundTransparent);
  }
}

@include desktopView {
  .app-main .content-frame-wrapper {
    @include themed(background-color, backgroundPrimary);
    border-radius: 0px;
    padding: 0;
    @include themed(border-color, transparent);

    &:has(.dislike-feedback-container),&:has(.chat-history-container) {
      @include themed(background-color, backgroundPrimary, null, !important);
    }

    &:has(.dislike-feedback-container) {
      .history-header-icon img {
        filter: invert(1);
      }
    }
  }
}
