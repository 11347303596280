@import './colors.scss';

// z-index hierarchy
$appOverlayComponentZIndex: 2147483647;
$mobileChatFormInputHeight: 40px;
$desktopChatFormInputHeight: 40px;
$desktopHeaderInlinePadding: 20px;
$mobileHeaderHeight: 64px;
$desktopHeaderHeight: 74px;
$scrollbarMarginRight: 18px;
$scrollbarBottomDistance: 30px;
$scrollbarTopDistance: 0px;
$summariesOverlayZIndex: 9999;
$TaskFormOverlayZIndex: 9999;
$WorkBlockFormOverlayZIndex: 999;
$settingsOverlayZIndex: 99999;
$swipeableItemZIndex5: 5;
$ZIndex999: 999;
$ZIndex99: 99;
$mobileNavbarZIndex: 9;
$mobileScreenMaxWidth: 599px;
$tabletsAndIpadsScreenMinWidth: 600px;
$tabletsAndIpadsScreenMaxWidth: 1024px;
$desktopScreenMinWidth: 600px;
$desktopNavbarWidth: 71px;
$mobileNavbarHeight: 80px;
$desktopChatBoxWidth: 63%;
$stageTasksDropdownFilterOverlayZIndex: $settingsOverlayZIndex;
$stageExpandAndCollapseTopLeftDistance: 40px;
$stageTasksToastMsgrOverlayZIndex: $settingsOverlayZIndex;
$chatAndStageContainerPaddingRightLeft: 0px;
$chatAndStageContainerPaddingTopBottom: 0px;
$createOrEditTaskFormSpaceRightBetweenScrollerToTheContent: 15px;
$createOrEditTaskOverlaysPositionRight: calc($chatAndStageContainerPaddingRightLeft + $scrollbarMarginRight + $createOrEditTaskFormSpaceRightBetweenScrollerToTheContent);
$taskInputHeight: 50px;
$overlayMobileHeightWhenPlacingTask: 108px;
$singleTaskHightStageTasks: 40px;
$miniTaskHeight: 18px;
$authLayoutFrameWrapperPaddingMobile: 6vh;
$deleteTaskConfirmModalTopPosition: calc($chatAndStageContainerPaddingTopBottom + 20px);
$appToastMobileBottomPosition: calc($mobileChatFormInputHeight + $mobileNavbarHeight + 10px);
$appOverlayCardWidthDesktop: 397;
$deleteTaskToastConfirmationWidth: 352px;
$calendarEventWhileResizingZIndex: $settingsOverlayZIndex;
$stageContentPaddingRightDesktop: 75px;
$stageContentPaddingLeftDesktop: 90px;
$stageContentMaxWidthOnExpendMode: 655px;
$stagePlanContainerPaddingRightLeftOnExpendMode: 137px;
$chatBarMarginBottom: 42px;
$calendarDistanceBetweenDaysOnMyWeek: 8px;
$mobileScreenEdgeSpacing: 20px;
$mobileChatBarFormBottomSpace: 16px;
$mobileScrollbarSpaceFromRigthEdge: 6px;
$desktopAppScrollbarWidth: 6px;