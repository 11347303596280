@import '../../../../styles/colors.scss';

.background-animation {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;

  &.fadeIn,
  &.fadeOut {
    animation-duration: 1.5s;
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    opacity: 0.9;
    background-image: url('../../../../assets/images/bot-thinking-BG.png');
    background-repeat: no-repeat;
    background-size: 600% 600%;
    animation: gradient 6s ease infinite;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 30%;
  }

  50% {
    background-position: 100% 90%;
  }

  100% {
    background-position: 0% 30%;
  }
}
