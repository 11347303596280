@import '../../../../../styles/sharedStyle.index.scss';

.download-session {
  margin-bottom: 40px;

  button {
    display: block;
    min-width: 100px;
    height: 30px;
    background-color:#334c45; // internal user feature
    border-radius: 5px;
    @include themed(color, textInvert);
  }
  
  small {
    @include themed(color, colorStateError);
    font-size: 12px;
  }

  #session-selector {
    margin-bottom: 0.5rem;
    width: fit-content;
  }
}
