@import '../../../../../../../styles/sharedStyle.index.scss';

.edit-notes-modal {
  &--container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include themed(background-color, backgroundPrimary);
    overflow: hidden;
    padding: 20px;
    padding-bottom: 0;
    @include displayFlex(flex-start, flex-start, column);
  }

  &--actions {
    width: 100%;
    @include displayFlex(space-between, center);

    &--back {
      @include transparencyBtn;
    }

    &--clear, &--clear:disabled{
      width: 52px;
      height: 21px;
      @include fontFootnote;
      @include themed(color, textPrimary);
      border-radius: 5px;
      @include themed(border, none);
      @include themed(background-color, transparent);
      text-decoration: underline;
      text-underline-offset: 3px;
      padding: 0;
    }
  }

  &--text-box {
    flex: 1;
    margin-top: 20px;
    width: 100%;
    padding: 0 8px 30px 8px;
    resize: none;
    @include themed(border, none);
    @include themed(background-color, backgroundTransparent);
    @include textTypography(16px, 400, 0.16px, 18px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::placeholder {
      @include themed(color, textSecondary);
    }

    &:focus {
      outline: none;
      @include themed(background-color, backgroundTransparent);
    }
  }
}
