@import '../../../../styles/sharedStyle.index.scss';
@import '../PhoneVerificationFlow.scss';

.login-with-phone-page form.enter-phone-number-get-otp,
.add-phone-registration-page form.enter-phone-number-get-otp,
form.enter-phone-number-get-otp {
  width: 100%;
  @include displayFlex(space-between, center, column);

  >* {
    width: 100%;
    max-width: 300px;
    text-align: center
  }

  .phone-form-content {
    text-align: center;
    width: 100%;
    flex: 1;
    @include displayFlex(flex-start, center, column);
    position: relative;

    .add-phone-registration-permission-text,
    .agreement-paragraph {
      white-space: pre-line;
    }

    .add-phone-registration-permission-text {
      display: block;
      @include textTypography(16px, 400, 0.16px);
      margin-bottom: 5px;

      &--main {
        width: 290px !important;
        margin-bottom: 44px;
      }
    }
  }
  
  .auth-next-btn {
    margin-bottom: 4vh !important;
  }

  .agreement-paragraph {
    @include fontCaption;
    white-space: pre-line;
    display: block;
  }

  .contact-us-container {
    margin-top: 5px;
  }

  .phone-input-label {
    @include fontBody;
    margin-bottom: 16px;
    display: block;
  }

  .app-phone-controllers-container {
    margin: 0 auto;
  }

  .enter-phone-legal-text {
    @include fontCaption;
    white-space: pre-line;
    margin-top: 46px;
    display: block;
  }
}

@include desktopView {
  form.enter-phone-number-get-otp {
    .agreement-paragraph {
      max-width: 240px;
    }
  }
}