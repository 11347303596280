@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';
@import '../../../../styles/layout.scss';

.calendar-day-container {
  height: auto;
  overflow: hidden;

  .calendar-events-container {
    position: relative;
    .calendar-hour-line {
      position: absolute;
      right: 0;
      width: 100%;
      @include themed(border-top, borderSecondary, 1px solid);
      user-select: none; /* Prevents text selection while long press event triggered in mobile */

      &--half {
        @include themed(border, transparent);
      }

      &--transparent {
        @include themed(border-top, transparent);
      }

      .calendar-hour-text {
        position: absolute;
        top: -8px;
        left: 0px;
        font-size: 10px;
        @include themed(color, textPrimary);
        @include themed(background-color, backgroundPrimary);
        font-weight: 700;
        text-align: left;
        width: 20px;
      }
    }

    &:has(.calendar-event.resizing) {
      .calendar-hour-line {
        cursor: ns-resize;
      }
    }
  }
}
