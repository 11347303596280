.main-task-with-subtasks-wrapper {
  .subtasks-list{
    margin-left: 32px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    &--expanded {
      max-height: var(--subtasksListMaxHeight);
    }
  }
}