@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.instructions-modal-overlay {
  max-width: 350px !important;
  height: 200px !important;
  border-radius: 8px 8px 8px 8px !important;
  @include themed(border, borderPrimary, 1px solid, !important);
  @include themed(box-shadow, borderPrimary, 0px 4px 0px 0px, !important);
  overflow: hidden;
}

.instructions-modal {
  font-family: 'Lato' !important;
  @include textTypography(16px, 400, 0.16px);
  white-space: pre-line;
  padding: 45px;
  z-index: $appOverlayComponentZIndex;
  width: 100%;
  position: relative;

  p {
    @include themed(color, textPrimary);
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }

  .close-button {
    position: absolute;
    top: 12px;
    left: 5px;
    @include themed(background-color, backgroundTransparent);
    @include themed(border, none);
    font-size: 16px;
    cursor: pointer;
    @include themed(color, textPrimary);
  }
}
