@import './sharedStyle.index.scss';

// this file includes the animation app in mobile view when the keyboard/taskForm/focus timer is open or close

@mixin hideNavBar {
  .resizable-container {
    .chat {
      padding-bottom: calc($mobileChatFormInputHeight + $mobileChatBarFormBottomSpace);
    }

    .navbar-container {
      display: none;
    }

    .form {
      transform: translate(-50%, $mobileNavbarHeight);
      height: $mobileChatFormInputHeight;

      &:has(.send-button) {
        height: auto;
        min-height: $mobileChatFormInputHeight;
      }

      section {
        padding: 0 !important;
        @include themed(border, none);
      }
    }
  }
}

@mixin showNavBar {
  .resizable-container {
    .chat {
      padding-bottom: calc(
        $mobileChatFormInputHeight + $mobileNavbarHeight + $mobileChatBarFormBottomSpace
      );
    }

    .chat .form {
      transform: translate(-50%, 0px);
    }

    .navbar-container {
      display: flex;
    }
  }
}

@mixin hideHeader {
  .resizable-container {
    .chat {
      margin-top: 0;

      .conversation {
        padding-top: 20px;
      }
    }

    header {
      overflow: hidden;
      height: 0;
    }
  }
}

@mixin showHeader {
  .resizable-container {
    header {
      overflow: visible;
      height: $mobileHeaderHeight;
    }
    .chat {
      margin-top: $mobileHeaderHeight;
    }
  }
}

@include mobileView {
  // hide navbar and header
  // animation while keyboard is open(focus) outside the taskForm / while open task-form
  body.input-focused:not(:has(.stage-container .details-dialog-animation-container)),
  body:has(.stage-container .details-dialog-animation-container:not(.slideOutDown)),
  body:has(.stage-container .plan-tasks-placement-list-container--full),
  body:has(.stage-container .plan-tasks-placement-list-container--placing) {
    @include hideNavBar;
    @include hideHeader;
  }

  // show navbar and header
  // animation while keyboard(blur)/taskForm getting close
  body.keyboard-close:not(:has(.stage-container .details-dialog-animation-container)),
  body:has(.stage-container .details-dialog-animation-container.slideOutDown):not(:has(.stage-container .stage-timer)) {
    @include showNavBar;
    @include showHeader;
  }

  // hide only the navbar when the focus timer is open
  // and preventing from the navbar to display after the task-details screen being close on the timer screen
  body:has(.stage-container .stage-timer), 
  body:has(.stage-container .details-dialog-animation-container.slideOutDown):has(.stage-container .stage-timer) {
    @include hideNavBar;
    @include showHeader;
  }
}

@keyframes shrinkOutNavBarAnimation {
  0% {
    height: $mobileNavbarHeight;
  }

  100% {
    height: 0px;
  }
}

@keyframes growInNavBarAnimation {
  0% {
    height: 0px;
  }

  100% {
    height: $mobileNavbarHeight;
  }
}

@keyframes shrinkOutHeaderAnimation {
  0% {
    height: $mobileHeaderHeight;
  }

  100% {
    height: 0px;
  }
}

@keyframes growInHeaderAnimation {
  0% {
    overflow: hidden;
    height: 0px;
  }

  100% {
    overflow: visible;
    height: $mobileHeaderHeight;
  }
}

@keyframes formPositionKeyboardOpenAnimation {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY($mobileNavbarHeight);
  }
}

@keyframes formPositionKeyboardCloseAnimation {
  0% {
    transform: translateY($mobileNavbarHeight);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes chatContainerKeyboardOpenAnimation {
  0% {
    margin-top: $mobileHeaderHeight;
    padding-bottom: calc($mobileChatFormInputHeight + $mobileNavbarHeight);
  }

  100% {
    margin-top: 0;
    padding-bottom: calc($mobileChatFormInputHeight);
  }
}

@keyframes chatContainerKeyboardCloseAnimation {
  0% {
    margin-top: 0;
    padding-bottom: calc($mobileChatFormInputHeight);
  }

  100% {
    margin-top: $mobileHeaderHeight;
    padding-bottom: calc($mobileChatFormInputHeight + $mobileNavbarHeight);
  }
}
