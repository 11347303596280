@import '../../../../../../../../styles/sharedStyle.index.scss';

.quick-options-recurrence {
  .task-overlay-flex-options-container {
    margin-bottom: 60px;
    .task-overlay-flex-options-inner-container {
      flex: 1;
    }
    .task-overlay-quick-options-single-option {
      width: 100%;
      padding: 1px 12px;
      height: 32px;
      border-radius: 12px;
      @include themed(background-color, backgroundSecondary);
      @include themed(color, textPrimary);

      .task-overlay-quick-options-single-option-content-container {
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
      }

      h2 {
        @include fontCaption;
        margin: 0;
      }
    }
  }
}
