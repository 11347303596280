// my-day
$dayCalendarEventPaddingTopBottom: 10px;
$dayCalendarEventPaddingRightLeft: 14px;
// my-week
$weekCalendarEventPaddingTopBottom: 8px;
$weekCalendarEventPaddingRightLeft: 4px;
$calendarEventTitleLineHeight: 18px;

:export{
  calendarEventTitleLineHeight: $calendarEventTitleLineHeight;
}