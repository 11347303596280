@import '../../../../../../styles/sharedStyle.index.scss';

$dropdownItemsMarginRight: 15px;
$dropdownMinWidth: 88px;
$dropdownToggleButtonHeight: 19px;

.stage-timer-share {
  position: relative;
  @include displayFlex(flex-end);
  min-width: $dropdownMinWidth;
  transition: width 0.4s ease-out;
  padding: 768 32px;
  width: 250px;

  .share-timer-icon {
    margin-left: 10px;
    margin-top: 10px;
  }
}

@include mobileView {
  .stage-timer-share {
    display: none;
  }

  body {
    &:has(.display-stage .chat .stage-timer-share),
    &:has(.display-stage .chat .stage-planner-container) {
      .stage-timer-share {
        display: inline-block;
      }
    }

    &:has(.app-modals-container .settings-menu) {
      .stage-timer-share {
        display: none !important;
      }
    }
  }
}

@include desktopView {
  .stage-timer-share {
    margin-left: 35px;
  }
}

// hide dropdown when message dislike feedback modal is open
body {
  &:has(.chat .message .dislike-feedback-container) {
    .stage-timer-share {
      display: none !important;
    }
  }
}
