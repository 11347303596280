@import '../../../../../styles/mixin.scss';

.open-close-history-btn-container {
  @include displayFlex(center, center);
  position: relative;

  .close-history,
  .history-header-icon {
    @include themed(border, transparent);
    @include themed(background-color, backgroundTransparent);
    padding: 0;
    position: absolute;
    top: auto;
    left: 0px;
  }

  .history-header-icon {
    border-radius: 50%;
  }
}
