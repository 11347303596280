@import '../../../styles/mixin.scss';

$desktopTimerCircleMaxSize: 500px; // the static size that defined on Figma
$mobileTimerCircleMaxSize: 312px; // the static size that defined on Figma

// Shared timer component styles
.timer-circle-container {
  position: relative;
  height: 80%;
  min-width: 200px;
  @include displayFlex(center, center);

  .timer-circle {
    width: 100%;
    height: 100%;
    @include themed(stroke, backgroundBrandMediumContrast);

    circle {
      @include themed(fill, none);
      stroke-width: 12;

      &.timer-circle-progress {
        stroke-linecap: round;
        transition: stroke-dasharray 0.5s ease-in-out, opacity 0.3s ease;

        &.initial {
          transition: opacity 0.3s ease;
        }
      }

      &.timer-dot {
        @include themed(stroke, backgroundBrandPrimary);
        @include themed(fill, backgroundBrandPrimary);
        border-radius: 10px;
      }
    }
  }

  .timer-content {
    position: absolute;
    @include displayFlex(center, center, column);
    text-align: center;
    width: 71%;
    max-width: 200px;
    row-gap: 8px;

    .timer-name {
      max-height: 50px;
      max-width: 100%;
      line-height: 24px;
      text-align: center;
      padding: 0;
      transition: all 0.3s;
      text-decoration: underline;
      text-underline-offset: 2px;
      @include fontBody;
      @include themed(color, textBrandAlt);
      @include themed(background-color, backgroundTransparent);
      @include multiLineEllipsis(2);
      &:hover {
        @include themed(color, textBrand);
      }
    }

    .timer-display {
      @include fontLargeTitle();
      @include themed(color, textPrimary);
    }

    .timer-name-ended-title {
      @include fontSmallTitle;
      @include themed(color, textPrimary);
    }

    .timer-name-ended-description {
      @include fontFootnote;
      @include themed(color, textPrimary);
    }

    &:has(.timer-name-ended-title) {
      row-gap: 12px;
    }
  }
}

@include mobileView {
  .timer-circle-container {
    max-width: $mobileTimerCircleMaxSize;
    max-height: $mobileTimerCircleMaxSize;
    min-height: $mobileTimerCircleMaxSize;  
    min-height: 220px;
  }
}

@include desktopView {
  .timer-circle-container {
    max-width: $desktopTimerCircleMaxSize;
    max-height: $desktopTimerCircleMaxSize;
    min-height: 260px;
  }
}
