@import '/src/styles/colors.scss';
@import '/src/styles/mixin.scss';
@import '/src/styles/font-size.scss';

.page-404 {
  display: flex;
  flex-direction: column;

  .flex-content-container {
    @include displayFlex(center, center, column);
    height: calc(100vh - var(--footer-height));
    text-align: center;

    h1,
    h3 {
      margin: 10px 0;
      cursor: default;
    }

    h1 {
      font-size: $font-128px;
      transition: font-size 200ms ease-in-out;
      border-bottom: 1px dashed;
    }

    h3 {
      margin-bottom: 24px;
    }

    a {
      border: 1px solid;
      border-radius: 13px;
      padding: 2px 8px;
      font-size: $font-19px;
      font-weight: bold;
      text-decoration: none;
    }
  }
}
