@import '../../../styles/sharedStyle.index.scss';

.details-dialog-animation-container {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: $summariesOverlayZIndex;
  text-align: start;
  overflow: hidden;

  .details-dialog-inner-container {
    height: 100%;
    width: 100%;
    @include themed(background-color, backgroundPrimary);
    position: relative;

    button.close-dialog {
      @include transparencyBtn;
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }
}

@include mobileView {
  .details-dialog-animation-container {
    @include themed(background-color, backgroundPrimary);
    padding-top: 4px;

    .details-dialog-inner-container {
      @include themed(border-top, borderPrimary, 1px solid);
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      padding-top: 50px;
    }
  }
}

@include desktopView {
  .details-dialog-animation-container {
    overflow: hidden;

    .details-dialog-inner-container {
      padding-right: 20px;
      padding-top: 60px;
    }
  }
}
