@import '../../../../styles/sharedStyle.index.scss';
@import '../PhoneVerificationFlow.scss';

.phone-verification-form {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;

  .phone-verification-form-content {
    width: 100%;
    margin: 0 auto;
    text-align: center;

    >* {
      margin-left: auto;
      margin-right: auto;
    }

    .phone-verification-text {
      @include fontBody;
      letter-spacing: 0.16px;
      white-space: pre-line;
    }

    .data-to-verify {
      font-size: 20px;
      @include fontTitle1;
      margin: 12px 0 5vh 0;
      @include themed(color, textBrand);
    }

    .verification-code-controllers-container {
      @include displayFlex(center, center, column);
      row-gap: 12px;

      .enter-code-verification,
      .resend-code-verification {
        @include fontCaption;
        @include themed(color, textPrimary);
      }

      .resend-code-verification {
        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .auth-next-btn {
    margin-top: 5vh;
  }
}