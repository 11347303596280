@import '../../../../styles/sharedStyle.index.scss';

.navbar-container {
  .action-buttons {
    width: 100%;

    button {
      border-radius: 15px;
      @include displayFlex(flex-start, center, column);
      row-gap: 5px;

      &:disabled {
        opacity: 0.6;
      }

      .icon-title {
        display: inline-block;
        @include fontCaption();
      }

      svg.navbar-icon > * {
        transition: all 0.3s;
      }

      &.inActive {
        svg.navbar-icon {
          & > * {
            @include themed(fill, transparentColor);
          }

          .active-fill {
            display: none;
          }

          line {
            @include themed(stroke, transparentColor);
          }
        }
      }
    }
  }

  button,
  .user-avatar {
    display: block;
    @include themed(border, none);
    @include themed(background-color, backgroundTransparent);
    padding: 0;
  }
}

@include mobileView {
  .navbar-container {
    width: 100%;
    height: $mobileNavbarHeight;
    @include displayFlex(center, flex-start);
    padding: 8px 19% 20px 19%;
    text-align: center;
    position: fixed;
    bottom: var(--offset-h, 0);
    @include themed(background-color, backgroundPrimary);
    z-index: $mobileNavbarZIndex;
    border-radius: 8px 8px 0 0;

    .action-buttons {
      @include displayFlex(space-between, flex-start);

      button {
        .icon-title {
          @include themed(color, textPrimary);
        }

        &.inActive {
          svg.navbar-icon > * {
            @include themed(stroke, textPrimary);
          }
        }
      }
    }

    .user-avatar {
      display: none;
    }
  }

  body:has(.product-tour-container) {
    .navbar-container {
      pointer-events: none;
    }
  }
}

@include desktopView {
  .navbar-container {
    width: $desktopNavbarWidth;
    height: 100%;
    @include displayFlex(space-between, center, column);
    padding-top: 44px;
    padding-bottom: 38px;
    position: relative;
    @include themed(background-color, backgroundTransparent);
    z-index: $settingsOverlayZIndex;

    .action-buttons {
      width: 100%;
      @include displayFlex(flex-start, center, column);

      .navbar-max-title {
        @include fontLargeTitle;
        font-size: 16px;
        @include themed(color, textInvert);
        margin-bottom: 44px;
      }

      button {
        margin-bottom: 40px;
        row-gap: 5px;

        .icon-title {
          @include themed(color, textInvert);
        }

        &.inActive {
          svg.navbar-icon > * {
            @include themed(stroke, textInvert);
            @include themed(fill, transparentColor);
          }
        }
      }
    }

    .user-avatar .navbar-avatar {
      border-width: 2px;
    }
  }
}
