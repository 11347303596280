@import '../../../../../../../styles/sharedStyle.index.scss';
@import '../CreateOrEditTask.mixins.scss';

$nameControllerPaddingBottom: 19px;
$nameControllerPaddingLeft: 32px;

.add-edit-task-container .task-name-controller.task-detail-section,
.work-block-dialog-container .work-block-name-controller.work-block-detail-section {
  padding-bottom: $nameControllerPaddingBottom;
  @include displayFlex(flex-start, center);
  position: relative;
  margin-top: 1px !important;
  padding-left: $nameControllerPaddingLeft;

  .app-checkbox.task-details-checkbox {
    position: absolute;
    left: 0;
    bottom: $nameControllerPaddingBottom;
    
    &::before,
    &::after {
      top: unset;
      bottom: 0;
      transform: unset;
    }
    &::before {
      @include themed(border-color, textSecondary);
    }
    // on active
    &::after{
      @include themed(background-color, textSecondary);
      @include themed(border-color, textSecondary);
    }
  }

  input.name-text {
    padding: 0;
    @include themed(border, none);
    @include themed(background-color, backgroundTransparent);
    @include fontBody;
    width: calc(100% - $nameControllerPaddingLeft);
    @include taskDetailsTextColor;

    &::placeholder {
      @include themed(color, textSecondary);
    }

    &:focus {
      outline: none;
    }
  }

  .task-name-error {
    @include textTypography(8px, 400, 0.08px);
    @include themed(background-color, colorStateError);
    position: absolute;
    bottom: -20px;
    left: $nameControllerPaddingLeft;
  }

  .priority-and-quick-win {
    @include displayFlex(flex-end, center);

    button {
      @include transparencyBtn;
    }

    .quick-win,.priority{
      &--on * {
        @include themed(fill, textPrimary);
      }
      &--off * {
        @include themed(fill, textBrandContrast);
      }
    } 
  }

  &.task-name-controller--event {
    padding-left: 0;

    // TODO: Remove after updating the all the color values across the app
    .event-name-icon:not(.task-detail-icon--active) * {
      @include themed(stroke, textSecondary);
    }
  }
}

@include mobileView {
  .task-name-controller,
  .work-block-name-controller {
    margin: 0 auto;

    .priority-and-quick-win {
      button {
        &.priority {
          margin-left: 29px;
        }
      }
    }
  }
}

@include desktopView {
  .task-name-controller,
  .work-block-name-controller {
    width: 100%;
    justify-content: space-between;

    .priority-and-quick-win {
      button {
        &.priority {
          margin-left: 29px;
        }
      }
    }
  }
}
