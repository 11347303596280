@import '../../../styles/sharedStyle.index.scss';

.swipeable-item-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: none;

  .swipeable-item {
    @include themed(background-color, backgroundTransparent);
    @include themed(border, none);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $swipeableItemZIndex5 !important;
    @include displayFlex(flex-start, center);
    padding: 0px;
    transform: translateX(0px);
    transition: transform 0.3s ease-out;
    //Provides a rendering hint to the user agent, stating what kinds of changes the author expects to perform on the element.
    will-change: transform;

    & > div,
    & > section {
      height: 100% !important;
    }
  }

  .swipeable-item-left-side,
  .swipeable-item-right-side {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  .swipeable-item-right-side {
    justify-content: flex-end;
  }
}

@include mobileView {
  .swipeable-item-container {
    display: block;
  }
}

@include TabletsAndIPadsView {
  .swipeable-item-container {
    display: block;
  }
}

.swipeable-item-container.display-swipeable-container-mobile {
  display: block;
}
