@import '../../../../../styles/sharedStyle.index.scss';

.message-date-time {
  @include fontCaption;
  height: 13px;
  text-align: left;
  @include themed(color, textInvert);
  margin-bottom: 8px;
}

// all but the first message on the session
.message.bot:not(:first-child) .message-date-time{
  margin-top: 8px;
}