@import '../../../../../../../../styles/sharedStyle.index.scss';

.reminder-custom-time-picker {
  .custom-string {
    font-size: 16px;
    letter-spacing: 0.16px;
    @include themed(color, textPrimary);
    margin-left: -2px;
  }
}
