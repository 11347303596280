@import '../../../styles/sharedStyle.index.scss';

.app-rich-text {
  width: 100%;
  @include themed(color, textPrimary);

  // placeholder
  [data-slate-placeholder='true'] {
    @include themed(color, inherit);
    opacity: 1 !important;
  }

  a {
    @include themed(color, brandTertiary);
  }

  &:focus,
  &:active {
    outline: none !important;
    max-height: max-content !important;
  }
}
