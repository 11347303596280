@import '../../../styles/sharedStyle.index.scss';

.app-time-picker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.app-time-picker-action-container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border-radius: 8px;
  @include themed(background-color, backgroundSecondary);
  position: relative;
}

.app-time-picker-action-buttons-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.app-time-picker-action-button {
  @include themed(background-color, backgroundTransparent);
  @include themed(color, inherit);
  @include themed(border, none);
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.app-time-picker-input-value {
  @include themed(color, textPrimary);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
