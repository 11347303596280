@import '../../../../styles/sharedStyle.index.scss';
@import '../CreatePlusButton.module.scss';


.planner-plus-overlay {
  @include displayFlex(flex-start, center, column);
  
  .plus-overlay-title {
    @include fontCaption;
    @include themed(color, textPrimary);
  }

  .plus-overlay-single-option {
    height: 55px;
    @include themed(background-color, backgroundBrandLowContrast);
    border-radius: 8px;
    padding: 0 18px;
    margin: 0 auto;
    @include displayFlex(space-between, center);
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .single-option-title {
      @include fontBody;
      @include themed(color, textPrimary);
    }
  }
}

@include mobileView {
  .planner-plus-overlay {
    padding-top: 17px;
    padding-bottom: 35px;
    user-select: none;
    width: 100%;

    .plus-overlay-title {
      margin-bottom: 17px;
    }

    .plus-overlay-single-option {
      width: 68%;
      max-width: 272px;
    }
  }
}

@include desktopView {
  .app-overlay-popover-background-container .app-overlay-popover-content-container.plan-plus-overlay-menu-container {
    width: auto;
  }
  .planner-plus-overlay {
    width: calc($planPlusMenuWidth * 1px);
    height: calc($planPlusMenuHeight * 1px);
    padding-top: 12px;

    .plus-overlay-title {
      margin-bottom: 12px;
    }

    .plus-overlay-single-option {
      width: 189px;
    }
  }
}