@import '../../../../../styles/sharedStyle.index.scss';

.stage-timer {
  @include displayFlex(space-between, center, column);
  padding: 14vh 40px $chatBarMarginBottom;
  height: 100%;
  row-gap: 30px;
  @include themed(background-color, backgroundTransparent);

  .stage-timer-circle-container {
    position: relative;
    width: 350px;
    height: 350px;
    margin-bottom: 2rem;
  }

  .stage-timer-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    @include displayFlex(flex-start, center, column);
    gap: 0.5rem;

    .stage-timer-name {
      @include themed(color, textBrandAlt);
      @include fontBody;

      &:hover {
        text-decoration: underline;
      }
    }

    .stage-timer-display {
      @include fontLargeTitle();
      @include themed(color, textPrimary);
    }
  }
}

@include mobileView {
  .stage-timer {
    padding: 10vh $mobileScreenEdgeSpacing 34px;
  }
}

@include desktopView {
  .display-stage.stage-expanded .stage-timer {
    padding-bottom: 130px;
    padding-top: 8vh;
  }
}
