@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixin.scss';
@import '../../../../../../styles/themes/theme-mixins.scss';

.invite-friends-card-container {
  &--copy {
    .copy-link-wrapper {
      position: relative;
      .copy-link-flex-container {
        width: 100%;
        display: flex;
        flex-direction: column;

        >*:not(:last-child) {
          margin-bottom: 14px;
        }

        .link-content {
          width: 100%;
          height: 51px;
          border-radius: 10px;
          padding: 10px;
          @include themed(background-color, backgroundBrandLowContrast);
          @include themed(border-color, transparent);
          @include fontCaption;
          line-height: 16px;
          letter-spacing: 0.11px;
          @include themed(color, textPrimary);
          padding-left: 12px;
          padding-right: 15px;
          @include displayFlex(flex-start, center);
          white-space: pre-line;
          overflow-wrap: anywhere;
        }
        .copy-link-text {
          @include fontFootnote;
          @include themed(color, textPrimary);
        }
      }

      .copy-button-container {
        width: 100%;
        @include displayFlex(flex-end, flex-start);
      }

      .copy-button {
        align-self: flex-end;
        @include fontFootnote;
        letter-spacing: 0.12px;
        width: 100px;
        height: 30px;
      }

      .error {
        letter-spacing: 0.12px;
        height: 11px;
        bottom: 11px;
      }
    }
  }
}
