@import '../../../../../../../styles/sharedStyle.index.scss';

.task-tags-container {
  .tags-list {
    width: 100%;
    @include displayFlex(flex-start, center);
    flex-wrap: wrap;
    row-gap: 10px;

    button.add-tag {
      @include transparencyBtn;
      @include displayFlex(center, center);
      height: 25px;
      border-radius: 5px;
      @include themed(border, none);
      @include themed(color, textSecondary);
    }
  }

  &--read-only {
    &:not(:has(.single-tag)) {
      margin: 0 !important;
    }

    .single-tag button.delete-tag {
      display: none !important;
    }
  }
}

.add-edit-task-container form.add-edit-task-form .single-tag,
.single-tag {
  width: fit-content;
  @include displayFlex(space-between, center);
  margin-right: 10px;
  padding: 0px 12px;
  border-radius: 8px;
  @include themed(color, textInvert);
  height: 25px;
  @include fontFootnote;
  
  &.without-name {
    justify-content: flex-end;
    min-width: 45px;
  }

  button.delete-tag {
    margin-left: 5px;
    @include transparencyBtn;
  }
}
