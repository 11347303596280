@import '../../../../styles/sharedStyle.index.scss';
@import './toast-messages/ToastMessages.scss';
@import './stage-planner/StagePlannerVariables.scss';

$toastMarginBottomOnExpend: 28px;
$maxWidthBotToastMessageOnExpend: 100%;
$bottomPositionBotToastMessageOnExpend: 0px;

@mixin toastNotificationInMobileAndExpand($marginBottom: 20px) {
  .toast-messages-container {
    &:has(.message.bot),
    &:has(.bot-response-error),
    &:has(.lottie-message-animation) {
      display: block !important;
      margin-bottom: $marginBottom;
    }
  }
}

.stage-container {
  @include themed(background-color, backgroundPrimary);

  .content-frame-wrapper {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    @include themed(background-color, backgroundPrimary);

    .confirm-modal-background {
      @include themed(background-color, backgroundTransparent);
    }

    .confirm-modal {
      top: 20px;
    }

    .transparency-background-while-overlay-opens {
      display: none;
    }
  }
}

@include mobileView {
  .stage-container .content-frame-wrapper {
    border-radius: 0;
    @include themed(border, none);
    box-shadow: none;
    width: 100%;
    height: 100%;
    max-width: 100%;

    .stage-content-wrapper,
    .screen-content {
      height: 100%;
    }

    .stage-content-para {
      max-width: 281px;
    }

    &:has(.add-edit-task-container) {
      overflow: hidden;
    }

    @include toastNotificationInMobileAndExpand;

    &:has(.details-dialog-animation-container:not(.slideOutDown)) {
      .stage-content-wrapper {
        height: 100%;

        .screen-content {
          padding-top: $mobileHeaderHeight;
        }
      }
    }
  }
}

@include desktopView {
  .stage-container {
    height: 100%;

    .content-frame-wrapper {
      overflow: hidden;
    }

    .stage-content-wrapper {
      height: calc(100% - $desktopHeaderHeight);
      @include displayFlex(space-between, flex-start, column);

      .stage-child-component,
      .screen-content {
        width: 100%;
        flex: 1;
        overflow: hidden;
      }

      .screen-content {
        position: relative;
      }
    }

    // when details-dialog form is open
    &:has(.details-dialog-animation-container) {
      .stage-header {
        position: absolute;
      }

      .stage-content-wrapper {
        height: 100%;

        .screen-content,
        .details-dialog-animation-container .stage-child-component {
          padding-top: $desktopHeaderHeight;
        }
      }
    }
  }

  @include mobileModeStagingToastMessages(
    $maxWidthBotToastMessageOnExpend,
    $bottomPositionBotToastMessageOnExpend
  );

  .stage-container .content-frame-wrapper .toast-messages-container {
    // hide toast while stage is not expanded
    &:has(.message.bot),
    &:has(.bot-response-error),
    &:has(.lottie-message-animation) {
      display: none !important;
    }
  }

  .display-stage.stage-expanded {
    .stage-container .content-frame-wrapper {
      // display toast when stage is expanded
      @include toastNotificationInMobileAndExpand($toastMarginBottomOnExpend);

      .toast-on-stage {
        width: 100%;
        max-width: $stageContentMaxWidthOnExpendMode;
        margin: 0 auto;
        position: relative;
        .toast-messages-container {
          left: 0;
        }
      }

      .chat-bar-form {
        z-index: $WorkBlockFormOverlayZIndex;
        max-width: $stageContentMaxWidthOnExpendMode;
        margin-left: calc(100% / 2 - ($stageContentMaxWidthOnExpendMode / 2));
        transition: all $plannerMenuTransitionTime;
      }

      &:has(.dislike-feedback-container) {
        .stage-header {
          display: none;
        }
      }
    }
  }

  body[data-planner-state='unscheduledtasksplacer'] {
    .display-stage.stage-expanded .stage-container .content-frame-wrapper .chat-bar-form {
      margin-left: calc((100% - $plannerWidthWhenOpen) / 2 - ($stageContentMaxWidthOnExpendMode / 2));
    }
  }

  // when the plan plus menu is open +
  // when overlay is open outside subtask details page
  body:has(.planner-plus-overlay),
  body:has(.task-edit-overlay-container):not(:has(.subtask-details-modal-container)) {
    .stage-container .content-frame-wrapper .transparency-background-while-overlay-opens {
      display: block;
      @include position(absolute, 0, 0, 0, 0);
      @include themed(background-color, colorOverlay);
      z-index: $summariesOverlayZIndex;
    }
  }

  // hide PlanTasksPlacementList content when it rendered directly inside stage-container in desktop view
  .stage-container .content-frame-wrapper {
    .plan-tasks-placement-list-container:not(.stage-planner-content-unscheduled-tasks-container
        .plan-tasks-placement-list-container) {
      display: none;
    }
  }
}
