@import '../../../../../../styles/sharedStyle.index.scss';
@import './CreateOrEditTask.mixins.scss';

$taskDetailsTextareaHeightOfOneLine: 24px;

.details-dialog-animation-container {
  z-index: $TaskFormOverlayZIndex;

  &.slideOutDown {
    animation: slideOutDownAnimation 0.5s forwards;
    -webkit-animation: slideOutDownAnimation 0.5s forwards;
  }
}

.add-edit-task-container,
.work-block-dialog-container {
  @include position(absolute, 0, 0, 0, 0);
  z-index: $TaskFormOverlayZIndex;
  @include themed(background-color, backgroundPrimary);
  text-align: start;

  &--subtask {
    .add-edit-task-form{
      justify-content: flex-start;
    }
    .parent-task-name-and-tag-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      margin-left: auto;

      .parent-task-name-header {
        text-align: right;
        @include fontBody;
        text-decoration: underline;
        @include themed(color, textPrimary);
      }
    }
  }

  &--header {
    @include displayFlex(space-between, flex-start);
    width: 100%;
    position: absolute;
    top: 20px;
    left: 0px;
    padding: 0 20px;

    &--title {
      margin-left: 20px;
      @include fontBody;
      @include themed(color, textBrand);
    }

    button.close-modal {
      @include transparencyBtn;
    }
  }

  button[type='submit'].save-button {
    @include fontBody;
    width: 80px;
    height: 30px;
    padding-bottom: 9px;
  }

  form.add-edit-task-form,
  .work-block-form {
    @include displayFlex(space-between, stretch, column);
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @include fontBody;

    .task-detail-icon {
      margin-right: 8px;
      * {
        @include themed(stroke, textSecondary);
      }
      &--active * {
        @include themed(stroke, textPrimary);
      }
      &--right {
        margin-left: 8px;
        margin-right: 0px;
      }
    }

    .task-textarea.app-rich-text, 
    .edit-notes-modal--text-box, 
    .task-input {
      width: 100%;
      resize: none;
      @include themed(border, none);
      @include themed(background-color, backgroundTransparent);
      @include fontBody;
      line-height: 24px;
      padding: 0;
      @include taskDetailsTextColor;

      &::placeholder,
      &.placeholder,
      [data-slate-placeholder='true'] {
        @include themed(color, textSecondary);
      }

      &:focus {
        outline: none;
        @include themed(border, none);
      }
    }
    .task-details-note{
      @include displayFlex(flex-start, flex-start);
      .task-detail-icon{
        margin-top: -2px;
      }
    }
    .task-notes {
      max-height: calc($taskDetailsTextareaHeightOfOneLine * 2);
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;

      &:focus {
        max-height: max-content;
        display: block;
      }
    }

    .default-value-notes {
      white-space: pre-line;
    }

    .create-or-edit-sub-title {
      @include themed(color, textTertiary);
      margin-bottom: 16px;
      font-weight: 400;
      @include fontFootnote;
    }
    
    button.delete-task-item {
      @include transparencyBtn;
      @include themed(color, colorStateError);
      width: fit-content;
      margin-top: 40px;
      svg.task-detail-icon * {
        @include themed(stroke, colorStateError);
      }
    }
  }

  // shared className for all the details sections

  .task-details-sub-section,
  .task-detail-section,
  .work-block-detail-section {
    @include themed(border-bottom, borderSecondary, 1px solid);
    padding-bottom: 16px;
    width: 100%;
  }

  .task-detail-section,
  .work-block-detail-section {
    margin-bottom: 24px;

    &--description {
      @include displayFlex(flex-start, flex-start);
    }
  }
  
  .task-details-sub-section {
    margin-bottom: 8px;
  }
}

@include mobileView {
  .details-dialog-animation-container {
    @include themed(background-color, backgroundPrimary);
  }
  
  .add-edit-task-container,
  .subtask-details-modal-container {
    @include themed(border-top, borderPrimary, 1px solid);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 4px;
  }

  .add-edit-task-container,
  .work-block-dialog-container {
    padding-top: 50px;
    height: 100%;

    &:has(.edit-notes-modal--container),
    &:has(.subtask-details-modal-container) {
      overflow: hidden;
    }

    button[type='submit'].save-button, 
    .delete-item {
      width: 80px;
      height: 30px;
    }

    form.add-edit-task-form,
    .work-block-form,
    .work-block-details-view {
      padding: 34px 20px 48px 20px;

      .task-description {
        &:focus {
          font-size: 16px;
        }
      }
    }
  }
}

@include desktopView {
  $saveBtnHeight: 40px;

  .add-edit-task-container,
  .work-block-dialog-container {
    padding-right: $scrollbarMarginRight;
    padding-top: calc($saveBtnHeight + 20px);

    &--header {
      padding-right: calc($createOrEditTaskFormSpaceRightBetweenScrollerToTheContent + $scrollbarMarginRight );
      padding-left: 30px;
    }

    button[type='submit'].save-button, 
    .delete-item {
      width: 100px;
      height: 32px;
    }

    form.add-edit-task-form,
    .work-block-form,
    .work-block-details-view {
      padding: 40px $createOrEditTaskFormSpaceRightBetweenScrollerToTheContent 26px 36px;
      @include designedScrollbar(12px);
    }
  }
}

@keyframes slideOutDownAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}