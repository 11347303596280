@import '../../../styles/sharedStyle.index.scss';

.app-button {
  @include displayFlex(center,center);
  padding: 8px 0px;
  flex-shrink: 0;
  @include fontBody;
  border-radius: 8px;
  border: none;
  transition: background-color 0.2s;

  &.auth-submit-btn,&.auth-next-btn {
    height: 40px;
    margin-top: 24px;
  }
}