@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';
@import '../../../../styles/layout.scss';

.header-container {
  width: 100%;
  @include themed(background-color, backgroundTransparent);

  .header-content {
    height: 100%;
    @include displayFlex(space-between, center);
  }
}

body:has(.chat-history-container) {
  header {
    @include themed(background-color, backgroundPrimary);
  }
}

@include mobileView {
  header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $summariesOverlayZIndex;
  }

  .header-container {
    height: $mobileHeaderHeight;
    padding: 0 $mobileScreenEdgeSpacing;

    .header-content {
      .header-title {
        @include fontSmallTitle(normal);
        @include themed(color, textBrand);
        white-space: nowrap;
      }

      .timer-header-content {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        .header-title {
          margin-right: auto;
        }

        .timer-share {
          margin-left: 0;
        }
      }
    }
  }

  .header-content {
    .my-plan-navigation {
      display: none;
    }
  }

  body:has(.settings-menu) {
    header {
      @include themed(background-color, backgroundPrimary);
    }

    .header-container .header-content {
      flex-direction: row-reverse;

      .close-settings {
        right: 0;
        left: 0px;
      }
    }

    body:has(.product-tour-container) {
      header {
        pointer-events: none;
        z-index: 1;
      }
    }
  }

  body:has(.product-tour-container) {
    header {
      z-index: unset;
    }
  }
}

@include desktopView {
  .header-container {
    @include headerContainerDesktop;

    .header-content {
      .header-title,
      .plan-view-selection,
      .planner-header-actions,
      .stage-tasks-dropdown-filter,
      .my-plan-navigation,
      .timer-header-content {
        display: none;
      }
    }
  }
}
