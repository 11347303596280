@import '../../../../../../styles/sharedStyle.index.scss';

.task-overlay-quick-options-single-option {
  width: auto;
  height: 32px;
  @include themed(background-color, backgroundTransparent);
  @include themed(color, inherit);
  @include themed(border, none);
  padding: 0 12px;
  font: inherit;
  outline: inherit;
  border-radius: 8px;
  @include themed(background-color, backgroundSecondary);
  padding: 0;
  padding-top: 5px;
  text-align: left;
  @include displayFlex(center, center, column);

  h2 {
    @include fontCaption;
    cursor: pointer;
    width: auto;
    @include themed(background-color, backgroundSecondary);
    @include themed(color, textPrimary);
  }

  p {
    @include themed(color, textPrimary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
