@import '../../../../../../styles/sharedStyle.index.scss';
@import '../StageTasksVariables.module.scss';

$dropdownItemsMarginRight: 15px;
$dropdownMinWidth: 88px;
$dropdownToggleButtonHeight: 19px;
$menuSpaceTopBottom: 24px;
$menuSpaceRightLeft: 20px;
$menuRowGap: 16px;

.stage-tasks-dropdown-filter {
  position: relative;
  display: inline-block;
  min-width: $dropdownMinWidth;
  transition: width 0.4s ease-out;
  width: 117px;

  .open-tasks-filter {
    @include displayFlex(flex-end, center);
    @include textTypography(16px, 700, 0.16px);
    height: $dropdownToggleButtonHeight;
    padding: 0px;
    border-radius: 5px;
    @include themed(border, transparent);
    @include themed(background-color, backgroundTransparent);
    white-space: nowrap;
    width: 100%;

    &--arrow-down {
      margin-top: 5px;
    }
  }

  .dropdown-menu {
    position: absolute;
    top: -29px;
    @include themed(background-color, backgroundBrandLowContrast);
    @include displayFlex(flex-start, flex-start, column);
    width: 140px;
    overflow: hidden;
    height: 0;
    transition: height $tasksFiltersTransitionDuration ease-out, width $tasksFiltersTransitionDuration ease-out;
    z-index: $stageTasksDropdownFilterOverlayZIndex;
    border-radius: 16px;
    text-align: left;

    .tasks-filter-scrollable-container {
      @include displayFlex(flex-start, flex-start, column);
      row-gap: $menuRowGap;
      width: 100%;
      height: 100%;
    }

    .dropdown-view {
      width: calc(100% - $menuSpaceRightLeft * 2);
      @include fontFootnote;
      @include themed(color, textPrimary);
      @include displayFlex(space-between, center);
      height: fit-content;
      margin: $menuSpaceTopBottom $menuSpaceRightLeft 0 $menuSpaceRightLeft;
    }

    .filter-title,
    .clear-tasks-filter {
      @include fontBody;
      @include themed(color, textPrimary);
    }

    .filter-buttons {
      @include displayFlex(flex-end, center);
      column-gap: 16px;
    }

    .close-tasks-filter,
    .clear-tasks-filter {
      @include transparencyBtn;
      padding: 0;
    }

    .clear-tasks-filter {
      text-decoration: underline;
    }

    .tags-title{
      margin-left: $menuSpaceRightLeft;
    }

    .filter-options-container {
      width: calc(100% - $menuSpaceRightLeft * 2);
      @include displayFlex(flex-start, center, row, wrap);
      gap: 8px;
      padding-left: $menuSpaceRightLeft;

      .single-tag {
        margin: 0;
      }
    }

    .dropdown-item {
      @include transparencyBtn;

      .tasks-filter-item {
        @include themed(background-color, backgroundBrandMediumContrast);
        transition: background-color 0.3s;
      }

      &.active .tasks-filter-item {
        @include themed(background-color, backgroundBrandContrast);
      }
      
      &.dropdown-tag-item {
        position: relative;
        overflow: hidden;

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          @include themed(background-color, colorOverlay);
          transition: opacity 0.3s;
          opacity: 0;
        }

        &.active {
          &::after {
            opacity: 0.5;
          }
        }
      }
    }
  }

  &.open {
    .open-tasks-filter {
      transition: $tasksFiltersTransitionDuration;
    }
  }
}

@include mobileView {
  .stage-tasks-dropdown-filter {
    display: none;

    .dropdown-menu {
      right: calc($mobileScreenEdgeSpacing * -1);

      .filter-options-container {
        &--tags {
          flex-wrap: nowrap;
          overflow-x: auto;
        }
      }
    }

    &.open {

      .dropdown-menu {
        height: $tasksFiltersOpenHeightMobile;
        width: 100vw;
      }
    }
  }

  body {

    &:has(.display-stage .chat .stage-tasks-container),
    &:has(.display-stage .chat .stage-planner-container) {
      .stage-tasks-dropdown-filter {
        display: inline-block;
      }
    }

    &:has(.app-modals-container .settings-menu) {
      .stage-tasks-dropdown-filter {
        display: none !important;
      }
    }
  }
}

@include desktopView {
  $scrollbarSpaceRight: 8px;
  .stage-tasks-dropdown-filter {
    // relative to .stage-header-content
    position: absolute;
    right: 0;
    width: 100%;

    .dropdown-menu {
      right: 0;
    }

    &.open {
      .dropdown-menu {
        height: $tasksFiltersOpenHeightDesktop;
        width: 100%;
        .tasks-filter-scrollable-container {
          overflow-y: auto;
          width: calc(100% - $scrollbarSpaceRight);
          @include designedScrollbar($menuSpaceTopBottom,$menuSpaceTopBottom);
        }
        .filter-options-container {
          &--tags {
            padding-bottom: $menuRowGap;
          }
        }
      }
    }
  }
}

.display-stage.stage-expanded .stage-tasks-container {
  .stage-tasks-dropdown-filter {
    display: none;
  }
}

// hide dropdown when message dislike feedback modal is open
body {
  &:has(.chat .message .dislike-feedback-container) {
    .stage-tasks-dropdown-filter {
      display: none !important;
    }
  }
}