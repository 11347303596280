@import '../../../../../../../styles/sharedStyle.index.scss';

@mixin swipeableTaskView {
  margin-bottom: 0px;
  @include themed(border-bottom, borderSecondary, 1px solid);
  
  .tasks-list-item {
    width: 100% !important;
    height: 100% !important;
    @include themed(border-bottom, none);
  }

  .swipeable-item.remove-border-radius .tasks-list-item {
    border-radius: 0px !important;
  }

  .swipeable-item-container {
    .swipeable-item-right-side,
    .swipeable-item-left-side {
      align-items: flex-end;
      padding-bottom: 4px;
    }
  }
}

.swipeable-task-item--mobile {
  @include swipeableTaskView;
}

@include mobileView {
  .swipeable-task-item {
    @include swipeableTaskView;
  }
}

@include TabletsAndIPadsView {
  .swipeable-task-item {
    @include swipeableTaskView;
  }
}