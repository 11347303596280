@import '../../../../../../../styles/colors.scss';
@import '../../../../../../../styles/mixin.scss';

.time-picker-context-container {
  min-height: 100px;
  width: 100%;
  position: relative;
  > * {
    width: 100%;
  }
  .time-picker-context-close-container {
    padding: 8px 8px 0;
    text-align: left;
    .time-picker-close-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .time-picker-context-contend-container {
    padding: 0 28px;
  }
  .time-picker-context-status {
    @include themed(color, textPrimary);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;
  }
  .time-picker-context-name {
    @include textTypography(16px, 400, 0.16px);
    @include themed(color, textPrimary);
    width: 85%;
    margin-top: 5px;
    margin-bottom: 4px;
  }
  .time-picker-selected-time {
    width: 85%;
    @include textTypography(14px, 400);
  }
  .time-picker-context-action-container {
    position: absolute;
    width: fit-content;
    .task-edit-overlay-header-action-button {
      height: 30px !important;
      width: 50px !important;
      font-size: 14px !important;
    }
  }
}

@include mobileView {
  .time-picker-context-container {
    height: $overlayMobileHeightWhenPlacingTask;

    .time-picker-context-action-container {
      // on mobile, the appOverlay width is 100vw, so the action button position should always be right:20px;
      left: unset !important;
      right: 20px !important;
    }
  }

  // on mobile, the time-picker-overlay is above the chat-form, so remove the border-radius from both except for the top outer corners
  body:has(.time-picker-context-container) {
    #chat-form {
      border-radius: 0 !important;
    }
    .app-overlay-popover-content-container,
    .time-picker-context-container {
      border-radius: 15px 15px 0 0 !important;
    }
  }
}
