@import '../../../../../../styles/sharedStyle.index.scss';

.work-block-details-view {
  width: 100%;
  padding-bottom: 30px !important;

  * {
    @include themed(color, textPrimary);
  }

  .switch-to-edit-work-block-form {
    @include transparencyBtn;
    position: absolute;
    top: 25px;
    right: 22px;
    text-decoration-line: underline;
    @include textTypography(10px, 400);
    align-items: center;

    svg.pen-icon {
      margin-right: 11px;
    }
  }

  .work-block-name,
  .work-block-date {
    @include fontBody;
  }

  .work-block-name {
    margin-bottom: 12px;
  }

  .work-block-date {
    margin-bottom: 40px;
  }

  // tasks
  .work-block-details-tasks-container {
    .tasks-title {
      @include fontCaption;
      @include themed(color, textTertiary);
      margin-bottom: 9px;
    }
  }

  // empty tasks state
  &:has(.work-block-details-empty-tasks-state) {
    height: 100%;
    @include displayFlex(space-between, flex-start, column);
  }
}
