@import '../../../../../../styles/sharedStyle.index.scss';

.focus-mode-button {
  position: absolute;
  right: 8px;
  padding: 4px;
  bottom: 8px;
  border-radius: 4px;
  @include themed(background-color, backgroundBrandContrast);
  @include themed(color, textInvert);
  @include themed(border, none);
  @include fontFootnote();
  cursor: pointer;
  z-index: 1;

  &:hover {
    @include themed(background-color, backgroundBrandContrast);
  }

  // Smaller button for week view
  [data-plan='my-week'] & {
    text-align: center;
    @include fontCaption();

    @include mobileView {
      display: none;
    }
  }
}