@import '../../../../../../../../styles/sharedStyle.index.scss';

.custom-recurrence {
  .custom-recurrence-sub-title {
    @include textTypography(16px, 500, 0.16px);
    margin-bottom: 5px;
  }
}

.recurrence-select-week-days {
  @include displayFlex(center, center);
  gap: 14px;

  button.week-day {
    display: block;
    @include textTypography(16px, 500);
    width: 38px;
    height: 73px;
    border-radius: 50px;
    @include themed(background-color, backgroundSecondary);
    @include themed(border, none);
    transition: all 0.5s;
    @include themed(color, textPrimary);

    &--selected {
      @include themed(background-color, brandSecondary);
      @include themed(color, textBrandHighContrast);
    }
  }
}

@include mobileView {
  .recurrence-select-week-days {
    margin-top: 50px;
  }
}

@include desktopView {
  .recurrence-select-week-days {
    margin-top: 38px;
  }
}
