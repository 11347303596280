@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixin.scss';
@import '../../../../../../styles/themes/theme-mixins.scss';

.task-edit-overlay-duration-picker-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.task-edit-overlay-duration-picker-preset-options-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .duration-options-flex-container {
    justify-content: space-between;
  }

  .duration-preset-button {
    @include fontCaption;
    cursor: pointer;
    width: auto;
    padding: 1px 12px;
    height: 32px;
    border-radius: 12px;
    @include themed(background-color, backgroundSecondary);
    @include themed(color, textPrimary);
    flex: 1;

    &--selected {
      background: $neutral400;
    }
  }
}

.task-edit-overlay-duration-custom-time-picker-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.task-edit-overlay-duration-picker-custom-selector-header {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  padding: 0 20px;
  @include textTypography(12px, 400, normal, normal);
  @include themed(color, textPrimary);
  font-family: 'Lato' !important;
}

.task-edit-overlay-duration-custom-time-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 50px;
  border-radius: 8px;
  @include themed(background-color, backgroundSecondary);
  position: relative;

  .task-edit-overlay-duration-picker-action-buttons-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    margin-right: 10px;
  }

  .task-edit-overlay-duration-custom-time-picker-value {
    @include textTypography(16px, 400, normal, normal);
    @include themed(color, textPrimary);
    text-align: center;
    font-family: 'Lato' !important;
  }
}