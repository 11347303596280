.fadeIn {
  animation: fadeInAnimation 0.5s forwards;
  -webkit-animation: fadeInAnimation 0.5s forwards;
}

.fadeOut {
  animation: fadeOutAnimation 0.5s forwards;
  -webkit-animation: fadeOutAnimation 0.5s forwards;
}

.fadeInSlideUp {
  animation: fadeInSlideUpAnimation 0.5s forwards;
  -webkit-animation: fadeInSlideUpAnimation 0.5s forwards;
}

.fadeInSlideDown {
  animation: fadeInSlideDownAnimation 0.5s forwards;
  -webkit-animation: fadeInSlideDownAnimation 0.5s forwards;
}

.slideLeft {
  animation: slideLeftAnimation 0.5s forwards;
  -webkit-animation: slideLeftAnimation 0.5s forwards;
}

.slideRight {
  animation: slideRightAnimation 0.5s forwards;
  -webkit-animation: slideRightAnimation 0.5s forwards;
}

.slideUp {
  animation: slideUpAnimation 0.5s forwards;
  -webkit-animation: slideUpAnimation 0.5s forwards;
}

.slideDown {
  animation: slideDownAnimation 0.5s forwards;
  -webkit-animation: slideDownAnimation 0.5s forwards;
}

.slideOutDown {
  animation: slideOutDownAnimation 0.5s forwards;
  -webkit-animation: slideOutDownAnimation 0.5s forwards;
}

.skeleton {
  animation: skeletonLoading 1s linear infinite alternate;
}

.rotation {
  animation: rotationAnimation 1s infinite linear;
  -webkit-animation: rotationAnimation 1s infinite linear;
}

// close by swipe animations
.fadeOutSlideUpClose {
  animation: fadeOutSlideUpAnimationClose 0.5s forwards, fadeOutAnimation 0.5s forwards;
}

.fadeOutSlideDownClose {
  animation: fadeOutSlideDownAnimationClose 0.5s forwards, fadeOutAnimation 0.5s forwards;
}

.fadeOutSlideRightClose {
  animation: fadeOutSlideRightAnimationClose 0.4s forwards, fadeOutAnimation 0.2s forwards;
}

.fadeOutSlideLeftClose {
  animation: fadeOutSlideLeftAnimationClose 0.4s forwards, fadeOutAnimation 0.2s forwards;
}

@keyframes fadeOutSlideUpAnimationClose {
  to {
    transform: translateY(-100%);
  }
}

@keyframes fadeOutSlideDownAnimationClose {
  to {
    transform: translateY(100%);
  }
}

@keyframes fadeOutSlideRightAnimationClose {
  to {
    transform: translateX(100%);
  }
}

@keyframes fadeOutSlideLeftAnimationClose {
  to {
    transform: translateX(-100%);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInSlideUpAnimation {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

@keyframes fadeInSlideDownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAnimation {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideRightAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slideUpAnimation {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideDownAnimation {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutDownAnimation {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@keyframes skeletonLoading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

@keyframes rotationAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
