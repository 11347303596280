@import '../../../../styles/mixin.scss';

.stage-timer-controls {
  @include displayFlex(center, flex-start, row);
  column-gap: 20px;

  .stage-timer-button {
    @include displayFlex(center, center);
    @include themed(border, none);
    border-radius: 24px;
    cursor: pointer;
    transition: all 0.2s ease;
    @include fontBody;
    font-weight: 700;
    line-height: 24px;
    @include themed(background-color, backgroundBrandLowContrast);
    @include themed(color, textTertiary);
    height: 48px;
    width: 48px;

    &.start-pause {
      @include themed(background-color, fabsPrimary);
    }

    .reset-timer-icon {
      @include themed(fill, none);
      @include themed(stroke, textPrimary);
    }

    .pause-timer-icon,
    .start-timer-icon {
      @include themed(fill, textContrast);
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: disabled;
      @include themed(background-color, backgroundBrandMediumContrast);
      @include themed(color, textDisabled);
      .stage-timer-button-icon * {
        @include themed(stroke, textDisabled);
      }
      .pause-timer-icon, .start-timer-icon {
        @include themed(color, backgroundBrandMediumContrast);
        @include themed(fill, textDisabled);
      }
    }
  }
}


@include mobileView {
  .stage-timer-controls {
    column-gap: 25px;
  }
}