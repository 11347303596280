@import '../../../../../../../styles/colors.scss';
@import '../../../../../../../styles/mixin.scss';

$checkboxSize: 16px;

.subtasks-container {
  .tasks-list-item {
    padding-left: 0px;

    .task-item-left {
      padding-left: 0px;

      label.task-complete-label {
        padding-left: calc($checkboxSize + 6px);
        &::before,
        &::after {
          width: $checkboxSize;
          min-width: $checkboxSize;
          height: $checkboxSize;
          min-height: $checkboxSize;
        }
      }
    }

    .task-item-left span.task-name {
      margin-left: 8px;
    }
  }
  .add-subtask-input-container {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 16px;
    
    .task-input {
      margin-left: -1px;

      &::placeholder {
        @include themed(color, textSecondary)
      }
    }
  }
}