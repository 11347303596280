@import '../../../../../../../styles/sharedStyle.index.scss';

.work-block-details-empty-tasks-state {
  @include displayFlex(flex-start, center);
  align-self: center;

  .empty-tasks-state-description {
    @include textTypography(16px, 500);
    @include themed(color, textPrimary);
    @include displayFlex(flex-start, center);
    height: 70px;
  }
}

@include mobileView {
  .work-block-details-empty-tasks-state {
    flex-direction: column;

    .empty-tasks-state-description {
      margin-top: 22px;
    }
  }
}

@include desktopView {
  .work-block-details-empty-tasks-state {
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: flex-end;
  }
}
