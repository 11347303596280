@import '../../../../../../styles/sharedStyle.index.scss';

.plan-today-button {
  height: 20px;
  padding: 0 4px;
  @include displayFlex(center, center);
  border-radius: 20px;
  @include themed(background-color, brandPrimary);
  @include themed(color, textInvert);
  @include fontFootnote;
  transition: all 0.3s;
}

// when today is inview, change the button color and make it disabled
body:has(#day-0.plan-day-card-container--inview) {
  .plan-today-button {
    @include themed(background-color, backgroundBrandLowContrast);
    @include themed(color, textPrimary); 
    pointer-events: none;
   }
}