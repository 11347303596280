@import '../../../../../styles/mixin.scss';
@import '../../../../../styles/colors.scss';

.proactive-reminders-container {
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.proactive-reminders-toggles-container {
  .text-and-toggle-container  {
    padding: 10px 0;
  }

  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  > *:last-child {
    margin-bottom: 24px;
  }
}

.proactive-reminders-header {
  @include textTypography(16px, 400);
}
