@import '../../../../../styles/mixin.scss';

.feedback-container {
  width: 100%;
  @include displayFlex(flex-end, center);
  overflow: hidden;
  margin-top: 4px;
  height: 14px;
  @include themed(color, textPrimary);

  .lottie-like-animation,
  .lottie-dislike-animation {
    cursor: pointer;
    width: 14px;

    svg {
      filter: brightness(0) invert(1); // invert the color of the svg from grey to white
    }

    &.disabled {
      pointer-events: none;
    }
  }

  .lottie-like-animation {
    margin-right: 10px;
  }
}
