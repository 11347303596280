@import '../../../../../../styles/sharedStyle.index.scss';

.task-edit-overlay-container {
  padding: 20px;
  padding-bottom: 50px;
  min-height: 157px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &:has(.task-tags-overlay) {
    justify-content: flex-start;
  }
  &:has(.task-edit-overlay-date-picker) {
    padding-bottom: 20px;
  }

  .task-edit-overlay-quick-options-custom-option {
    width: 100%;
    height: 40px;
    font-size: 14px;
    @include themed(color, textPrimary);
    border-radius: 8px;
    @include themed(background-color, backgroundSecondary);
    @include themed(border, transparent);
    text-align: start;
    padding: 0 13px;

    span {
      &:first-of-type {
        display: none;
      }
    }
  }
}

.task-edit-overlay-date-picker {
  margin-bottom: 37px;
}

.task-edit-overlay-container:has(.app-time-picker-container) {
  .task-edit-overlay-date-picker {
    margin-bottom: 34px;
  }
}

.task-edit-overlay-time-picker {
  margin-bottom: 38px;
}
