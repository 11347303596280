@import '../../../styles/sharedStyle.index.scss';

.app-pop-up-toast {
  width: 100%;
  padding: 16px;
  padding-right: 14px;
  @include displayFlex(flex-start, flex-start, column);
  row-gap: 16px;

  &:has(.app-pop-up-toast--body) {
    row-gap: 24px;
  }

  button {
    @include transparencyBtn;
    align-items: center;
    justify-content: center;
  }

  &--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 12px;
  }

  .app-pop-up-toast-close-icon {
    width: 24px;
    height: 24px;
  }

  &--title,
  &--body {
    @include themed(color, textPrimary);
  }

  &--title,
  &--body {
    width: 90%;
  }

  &--title {
    @include fontBody;
  }

  &--body {
    display: block;
    @include fontFootnote;
  }

  &--actions {
    @include displayFlex(flex-start, center);
    width: 100%;
    column-gap: 25px;
    
    &--dont-show-again,
    &--cta {
      text-decoration: none;
      text-decoration-line: underline;
      @include themed(color, textPrimary);
      @include fontFootnote;
    }
  }
}

#discord-toast {
  .app-pop-up-toast {
    &--title {
      @include fontBody;
      margin-bottom: 24px;
    }
  }
}

// custom style for AppOverlayPopover
.app-overlay-popover-background-container {
  &:has(.app-overlay-popover-content-container.app-toast-overlay-container) {
    @include themed(background-color, backgroundTransparent);
  }
  .app-overlay-popover-content-container {
    &.app-toast-overlay-container {
      max-width: 352px !important;
      min-height: 100px;
      border-radius: 8px 8px 8px 8px !important;
      @include themed(border, none, null, !important);
      box-shadow: none !important;
      @include themed(background-color, backgroundBrandSecondary, null, !important);
      overflow: hidden !important;
      &.delete-task-modal {
        max-width: $deleteTaskToastConfirmationWidth !important;
      }
    }
  }
}

@include mobileView {
  .app-overlay-popover-background-container .app-overlay-popover-content-container {
    &.app-toast-overlay-container:not(.delete-task-modal) {
      top: unset !important;
      left: 50% !important;
      bottom: $appToastMobileBottomPosition !important;
      transform: translate(-50%, 0%) !important;
      width: 90%;
      margin: 0 auto;
    }
  }
}

@include desktopView {
  .app-overlay-popover-background-container .app-overlay-popover-content-container {
    &.app-toast-overlay-container:not(.delete-task-modal) {
      min-width: 352px !important;
      left: unset !important;
      top: unset !important;
      transform: unset !important;
      right: 50px !important;
      bottom: 50px !important;
    }
  }
}

// Hiding the app-toast while the product/plan tour is open
body:has(.product-tour-container) {
  .app-overlay-popover-background-container{
    &:has(.app-overlay-popover-content-container.app-toast-overlay-container) {
      display: none;
    }
  }
}