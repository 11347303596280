@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.avatar {
  @include themed(border, textInvert, 2px solid);
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.default-avatar {
    height: 32px;
    width: 32px;
    @include themed(border, none);
    font-weight: 500;
    font-style: normal;
    @include themed(color, textPrimary);
    @include displayFlex(center, center);
  }
}
