@import '../../../../../../../styles/sharedStyle.index.scss';
@import '../../create-or-edit-task/CreateOrEditTask.mixins.scss';

$colorCircleSize: 20px;

.task-tags-overlay {
  width: 100%;
  margin-top: 0;

  input {
    @include fontBody;
    @include themed(background-color, backgroundTransparent);
    @include themed(border, none);
    @include themed(border-bottom, borderPrimary, 1px solid);
    padding-bottom: 10px;
    display: block;
    width: 100%;
    @include taskDetailsTextColor;
    margin-bottom: 21px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      @include themed(color, textPlaceholder);
    }
  }

  .colors-container {
    @include displayFlex(flex-start, center);
    width: 100%;
    gap: 9px;

    button.tag-color {
      width:$colorCircleSize;
      min-width:$colorCircleSize;
      max-width:$colorCircleSize;
      height: $colorCircleSize;
      min-height: $colorCircleSize;
      max-height: $colorCircleSize;
      border-radius: 50%;
      @include themed(border, transparent, 3px solid);
      padding: 0;
      
      &.selected {
        @include themed(border, borderBrandHeavyContrast, 2px solid);
        @include themed(outline, backgroundPrimary, 1px solid);
        outline-offset: -3px;
      }
      
      &.transparent {
        @include themed(border, borderPrimary, 1px solid);
        
        &.selected {
          @include themed(border, borderBrandHeavyContrast, 3px solid);
          outline: none;
        }
      }
    }
  }

  .recent-tags-container {
    margin-top: 20px;

    .recent-tags-title {
      @include fontCaption;
      height: 16px;
      @include themed(color, textBrand);
      @include displayFlex(flex-start, flex-end);
      margin-bottom: 16px;
    }

    .recent-tags-list {
      @include displayFlex(flex-start, flex-start, row, wrap);
      row-gap: 7px;

      .single-tag {
        height: 28px;
        min-width: 50px;
        @include displayFlex(center, center);
        border-radius: 8px;
        cursor: pointer;
        @include fontFootnote;
      }
    }
  }
}
