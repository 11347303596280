@import 'react-day-picker/dist/style.css';
@import '../../../styles/sharedStyle.index.scss';

// react-day-picker style overrides
.rdp {
  --rdp-cell-size: 32px;
  /* Size of the day cells. */
  --rdp-caption-font-size: 16px;
  /* Font size for the caption labels. */
  --rdp-accent-color: transparent;
  /* Accent color for the background of selected days. */
  --rdp-background-color: transparent;
  /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #3003e1;
  /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #180270;
  /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color);
  /* Color of selected day text */
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  margin: 0;
  width: 100%;

  .rdp-month,
  .rdp-months,
  .rdp-table {
    max-width: unset;
    height: 100%;
    width: 100%;
  }

  .rdp-caption_dropdowns {
    .rdp-caption_label {
      @include fontSmallTitle(-0.24px);
      font-feature-settings: 'clig' off, 'liga' off;
      @include themed(color, textBrand);
    }
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected):not(.rdp-day_today) {
    @include themed(background-color, backgroundTransparent);
    @include themed(border, brandPrimary, 1px solid);
  }
  
  .rdp-nav_button {
    width: 24px;
    height: 24px;
    @include themed(color, textPrimary);
  }

  .rdp-day {
    margin: 0 auto;
    // space between rows
    margin-top: 8px;
    font-size: 16px !important;
    @include themed(color, textPrimary);
    
    &.rdp-day_today.rdp-day_selected,
    &.rdp-day_selected {
      @include themed(color, textBrandHighContrast);
      @include themed(background-color, brandSecondary);
    }

    &.before-today{
      @include themed(color, textSecondary);
    }

    &.before-today,
    &.rdp-day_outside {
      opacity: 1;
    }

    &.rdp-day_today,
    &.rdp-day_today:hover {
      @include themed(background-color, backgroundTransparent);
      @include themed(border, brandPrimary, 1px solid);
      font-weight: 400;
    }
    &:hover,
    &.rdp-day_today.rdp-day_selected {
      @include themed(border, brandPrimary, 1px solid);
    }
  }

  .rdp-head_row {
    .rdp-head_cell {
      font-family: 'Dosis', sans-serif !important;
      font-size: 16px;
      font-weight: normal;
      padding-left: 8px;
      @include themed(color, transparentColor);
    }
    
    .rdp-head_cell::first-letter {
      @include themed(color, textBrand);
    }
  }

  .rdp-caption {
    margin-bottom: 10px;
  }

  .rdp-caption_dropdowns {
    @include themed(color, textPrimary);
  }

  .rdp-caption_label {
    font-family: 'Dosis' !important;
    font-size: 16px;
    font-weight: normal;
  }
}
