@import '../../../styles/sharedStyle.index.scss';

.discord-link * {
  @include fontFootnote;
  line-height: 16px;
  text-align: left;
  @include themed(color, textPrimary);

  span {
    @include themed(color, textPrimary);
  }
}
