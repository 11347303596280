@import '../../../styles/sharedStyle.index.scss';
@import '../../chat-wrapper/resizable-container/stage-container/stage-planner/StagePlannerVariables.scss';

$myCardMobileHeaderHeight: 29px;
$myCardDesktopHeaderHeight: 29px;
$myCardDesktopHeaderMarginBottom: 25px;

.plan-scroll-container .plan-day-card-container {
  @include themed(background-color, backgroundTransparent);

  &--display-type-2 {
    .plan-day-card-content--scheduled-tasks {
      display: none;
    }
  }
}

.plan-scroll-container .plan-day-card-container {
  .plan-day-card-content {
    height: 100%;
    @include themed(background-color, transparent);
    padding: 0 !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .plan-day-card-content--scheduled-tasks,
    .plan-day-card-content--calendar-day {
      flex: 1 1 auto;
      overflow: hidden;
    }

    .plan-day-card-content--due-tasks.plan-day-card-content--due-tasks--total-0 {
      flex: unset !important;
    }

    .plan-day-card-content-scrollable-container {
      overflow-y: auto;
      height: 100%;

      // TODO: remove after implement corduroy for the Plan area
      .plan-day-card-due-tasks-container {

        .tasks-list-item,
        .swipeable-task-item {
          @include themed(background-color, backgroundPrimary);

          .due-date-tag,
          .task-subtasks-info {
            display: none;
          }
        }
      }
    }

    .plan-day-card-content--due-tasks {
      margin-bottom: 24px;
      @include themed(background-color, backgroundPrimary);
      box-shadow: none;
      @include themed(color, textPrimary);
      @include displayFlex(flex-start, flex-start);

      .calendar-header-due-section-title {
        margin-top: 12px;
        margin-right: 12px;
        @include themed(color, textPrimary);
        @include fontCaption;
      }

      &--total-0 {
        .calendar-header-due-section-title {
          display: none;
        }
      }

      .plan-day-card-content-scrollable-container {
        flex: 1;
      }
    }
  }

  .plan-day-header-and-due-tasks-container {
    display: flex !important;

    .plan-day-header-and-due-tasks-inner-container {
      width: 100% !important;
      min-width: 0 !important;
    }
  }

  .plan-card-header {
    margin-bottom: unset;
    border-radius: 0;
    margin-bottom: 25px;
  }

  &:has(.plan-day-card-content--due-tasks--total-0) :has(.plan-day-card-content--scheduled-tasks--total-0) .plan-card-header {
    margin-bottom: 15px;
  }
}

.plan-day-card-full-day-item-container-inner-container {
  height: 23px;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  padding: 0 4px;
  padding-right: 10px;
  align-items: center;
  justify-content: flex-start;
  @include themed(background-color, backgroundBrandLowContrast);
  border-radius: 8px;

  .plan-day-card-full-day-text-container {
    @include fontFootnote;
    overflow: hidden;
    width: 100%;
    @include displayFlex(space-between, center);
    .plan-day-card-full-day-title-container{
      @include displayFlex(flex-start, center);
      flex: 1;
      width: 50%;
    }
    .plan-day-card-full-day-title {
      @include fontFootnote;
    }

    img {
      width: 12px;
      height: 12px;
      margin-right: 3px;
    }

    .plan-day-card-full-day-addition-text-container {
      margin-left: 2px;

      .plan-day-card-full-day-addition-text {
        @include fontCaption;
        @include themed(color, textPrimary);
      }
    }
  }

  .plan-day-card-full-day-title {
    @include fontFootnote();
  }

  .plan-day-card-full-day-title,
  .plan-day-card-full-day-addition-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-2{
  .plan-day-card-full-day-item-container-inner-container {
    height: $singleTaskHightStageTasks;
    padding: 0 18px;
    .plan-day-card-full-day-text-container{
      .plan-day-card-full-day-title-container{
        width: 80%;
      }
      .plan-day-card-full-day-title,
      .plan-day-card-full-day-addition-text-container .plan-day-card-full-day-addition-text {
        @include fontBody();
      }
      img {
        margin-right: 16px;
      }
    }
  }
}

.plan-day-card-empty-filter,
.plan-day-card-content--empty {
  @include fontBody();
  @include themed(color, textBrand);
  display: none;
}

.plan-day-card-container :not(.plan-day-card-container--loading) {
  :has(.plan-day-card-content--due-tasks--total-0):has(.plan-day-card-content--scheduled-tasks--total-0) {

    .plan-day-card-content--due-tasks--total-0,
    .plan-day-card-content--scheduled-tasks--total-0 {
      display: none;
    }
  }

  :has(.plan-day-card-content--due-tasks--total-0):not(:has(.plan-day-card-content--scheduled-tasks--total-0)) {
    .plan-day-card-content--due-tasks--total-0 {
      .plan-day-card-empty-filter {
        display: inline;
      }
    }

    .plan-day-card-content--empty {
      display: none;
    }
  }

  :not(:has(.plan-day-card-content--due-tasks--total-0)):has(.plan-day-card-content--scheduled-tasks--total-0) {
    .plan-day-card-content--scheduled-tasks--total-0 {
      .plan-day-card-empty-filter {
        display: block;
      }
    }

    .plan-day-card-content--empty {
      display: none;
    }
  }

  :not(:has(.plan-day-card-content--due-tasks--total-0)):not(:has(.plan-day-card-content--scheduled-tasks--total-0)) {
    .plan-day-card-content--empty {
      display: none;
    }
  }

  :has(.plan-day-card-content--due-tasks--total-0):has(.plan-day-card-content--scheduled-tasks--total-0) {
    padding: 20px 23px;
  }
}

.plan-day-card-container.plan-day-card-container--display-type-2 :not(.plan-day-card-container--loading) {
  &:has(.plan-day-card-content--due-tasks--total-0) .plan-card-header {
    border-radius: 0;
    margin-bottom: 10px;
  }

  &:has(.plan-day-card-content--due-tasks--total-0) {
    .plan-day-card-inner-container .plan-card-header {
      margin-bottom: unset !important;
      border-radius: 0 !important;
    }
  }
}

.plan-day-card-container.plan-day-card-container {

  &--display-type-2,
  &--display-type-3 {
    &:not(.plan-day-card-container--loading) {
      &:has(.plan-day-card-content--due-tasks--total-0) {
        .plan-day-card-content--due-tasks--total-0 {
          display: block !important;

          .plan-day-card-empty-filter {
            display: inline;
          }
        }
      }
    }
  }
}


.plan-day-card-inner-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .plan-day-weeks-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include themed(background-color, backgroundTransparent);
    border-radius: 0px;
    margin-bottom: 14px;
    padding: 10px;
    margin-bottom: $myCardDesktopHeaderMarginBottom;

    .plan-day-weeks-header {
      @include themed(color, textBrand);
      @include fontSmallTitle();
    }
  }
}

.plan-card-header {
  display: flex;
  align-items: center;
  height: $myCardDesktopHeaderHeight;
  @include themed(color, textBrand);
  @include themed(background-color, backgroundPrimary);
  margin-bottom: $myCardDesktopHeaderMarginBottom;

  .plan-card-header-day {
    @include fontSmallTitle();
  }
}

.plan-day-card-content--empty {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.plan-day-card-content--calendar-day {
  @include themed(background-color, backgroundPrimary);
  border-radius: 0px;
  overflow: auto !important;

  >* {
    @include themed(background-color, backgroundTransparent);
  }

  .calendar-day-container {
    width: 100%;
  }
}

.plan-day-card-content--scheduled-tasks {
  @include themed(background-color, backgroundPrimary);
  border-radius: 0px;
}

.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-1,
.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-2 {
  .plan-day-weeks-header-container {
    display: none;
  }
}

.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-1,
.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-2 {
  .plan-card-header {
    justify-content: space-between;
  }
}

.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-3 {
  .plan-card-header {
    .my-plan-navigation {
      display: none;
    }
  }

  .plan-day-header-and-due-tasks-container .plan-day-header-and-due-tasks-inner-container {
    .single-task-container {
      margin-bottom: 10px;
    }

    .tasks-list-item.plan-day-card-due-task-item {
      @include themed(border, none);
      height: $miniTaskHeight;
      padding-left: 4px;

      .task-item-left {
        padding-left: 0;

        label.task-complete-label {
          padding-left: 10px;
          width: 10px;
          min-height: 10px;
          max-height: 10px;
          margin-top: 1px;

          &::after,
          &::before {
            width: 6px;
            height: 6px;
            border-width: 2px;
            background-size: 6px;
          }
        }

        .task-name-container span.task-name {
          margin-left: 4px;
          @include fontFootnote();
        }
      }
    }
  }

  .plan-day-card-content--empty {
    display: none;
  }
}

.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-1 {
  .plan-day-card-content--calendar-day {
    display: none;
  }
}

.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-2 {
  .plan-day-card-content {
    padding: 0;
  }

  .plan-day-card-content--empty {
    display: none;
  }
}

.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-3 {
  .plan-day-card-content {
    @include themed(background-color, backgroundPrimary);
    border-radius: 0px;
  }

  .plan-day-header-and-due-tasks-inner-container,
  .calendar-day-container {
    &:not(:last-of-type) {
      @include themed(border-right, backgroundPrimary, $calendarDistanceBetweenDaysOnMyWeek solid);
    }
  }

  .plan-day-card-content--scheduled-tasks {
    display: none;
  }

  .plan-day-card-content--due-tasks--total-0 {
    .plan-day-card-empty-filter {
      @include fontFootnote();
      @include themed(color, textSecondary);
    }
  }

  .plan-day-header-and-due-tasks-container {

    .plan-card-header,
    .plan-day-card-content--due-tasks {
      border-radius: unset;
    }

    .plan-day-card-content--due-tasks {
      margin-bottom: 0;

      .click-for-more-container {
        text-align: start;
        padding-left: 5px;
        height: $miniTaskHeight;
        @include displayFlex(flex-start, center);

        span {
          @include fontCaption;
          text-decoration-line: underline;
          text-underline-offset: 2px;
          @include themed(color, textPrimary);
        }
      }

      .single-task-container {
        overflow: hidden;

        .tasks-list-item {
          display: flex !important;

          &.single-task-desktop-view,
          .task-actions-container {
            display: flex !important;
          }
        }

        .tasks-list-item .task-item-left {
          max-width: 100% !important;
        }
      }
    }
  }

  .plan-day-card-content--calendar-day {
    border-radius: unset;

    .calendar-day-all-day-events-container {
      display: none;
    }
  }

  .plan-day-card-content--due-tasks .plan-day-card-content-scrollable-container {
    overflow: hidden !important;
  }

  .plan-day-card-content--due-tasks--total-0 {
    display: none;
  }

  .plan-card-header,
  .plan-day-card-content--calendar-day,
  .plan-day-card-content--due-tasks {
    @include themed(background-color, unset);
  }

  .calendar-day-container.calendar-day-container--today,
  .plan-day-header-and-due-tasks-inner-container.plan-day-header-and-due-tasks-inner-container--today {
    @include themed(background-color, backgroundPrimary);
  }
}

.plan-day-card-content--scheduled-tasks {
  animation: fadeInAnimation 2s;
}

.plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-3 {
  .plan-day-header-and-due-tasks-inner-container {
    padding-left: 6px;
  }

  .plan-card-header {
    align-self: center;

    .plan-card-header-day {
      @include fontBody;
      @include themed(color, textPrimary);
      @include displayFlex(flex-start, flex-start, column);

      &::after {
        content: '';
        display: block;
        @include separationLine(backgroundBrandMediumContrast, 16px, 3px);
        // @include themed(border-bottom-color, backgroundBrandMediumContrast);
        margin: 0;
        margin-top: 10px;
        border-radius: 30px;
      }

      &--today {
        @include themed(color, textBrand);
        
        &::after {
          @include themed(border-bottom-color, brandSecondary);
        }
      }
    }
  }
}

@include desktopView {

  .plan-day-card-content--due-tasks.plan-day-card-content--due-tasks--total-0,
  .plan-day-card-content--scheduled-tasks.plan-day-card-content--scheduled-tasks--total-0 {
    .plan-day-card-empty-filter {
      padding: 0;
    }
  }

  .plan-day-card-content--scheduled-tasks,
  .plan-day-card-content--due-tasks {
    display: flex;
    flex-direction: column;
  }

  .plan-day-card-content--due-tasks:not(.plan-day-card-content--due-tasks--total-0) {
    flex: 1 1 $taskInputHeight * 7 !important;
  }

  .plan-day-card-content--due-tasks.plan-day-card-content--due-tasks--total-1,
  .plan-day-card-content--due-tasks.plan-day-card-content--due-tasks--total-2 {
    flex: 1 1 auto !important;
  }

  .plan-day-card-content--scheduled-tasks:not(.plan-day-card-content--scheduled-tasks--total-0),
  .plan-day-card-content--calendar-day {
    flex: 2 1 100% !important;
  }

  .plan-card-header {
    margin-bottom: $myCardDesktopHeaderMarginBottom;
    justify-content: center;
  }

  .plan-day-card-container.plan-day-card-container--display-type-3 {
    .plan-card-header {
      justify-content: flex-start;
    }
  }

  .plan-day-card-content {
    height: calc(100% - ($myCardDesktopHeaderHeight + $myCardDesktopHeaderMarginBottom));
    overflow-y: auto;
  }

  .plan-day-card-content--due-tasks {
    margin-bottom: 15px;
  }

  .plan-day-card-content--due-tasks,
  .plan-day-card-content--scheduled-tasks {
    >* {
      margin-bottom: 10px;
    }
  }

  .plan-scroll-container .plan-day-card-container .plan-day-card-content .plan-day-card-content--due-tasks .plan-day-card-content-scrollable-container {
    max-height: 180px;
  }

  .plan-day-card-content--due-tasks.plan-day-card-content--due-tasks--total-0 {
    flex: 1 1 50px !important;
    min-height: 50px;
  }

  .plan-day-card-content--due-tasks:not(.plan-day-card-content--due-tasks--total-0),
  .plan-day-card-content--scheduled-tasks:not(.plan-day-card-content--due-tasks--total-0) {
    overflow: hidden;

    .plan-day-card-content-scrollable-container {
      padding: 0;
    }
  }

  .plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-3 {
    .plan-day-card-content--due-tasks .plan-day-card-content-scrollable-container {
      max-height: 190px !important;
      height: auto;
    }
  }

  .plan-day-card-container.plan-day-card-container--display-type-2 {
    .plan-day-card-content--calendar-day {
      @include themed(background-color, backgroundTransparent, null, !important);
      padding-right: 10px;
      border-radius: 0px;
    }
  }
}

@include mobileView {
  .plan-scroll-container .plan-day-card-container {
    @include themed(background-color, backgroundTransparent);
    min-height: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 0px;
    @include themed(border, transparent, 1px solid);
    transition: all 0.3s;
    padding: 0 $mobileScreenEdgeSpacing;
  }

  .plan-day-card-content {
    @include themed(background-color, backgroundPrimary);
    flex: 1;
    padding: 0;
  }

  .plan-card-header {
    height: $myCardMobileHeaderHeight;
  }

  .plan-day-card-content--due-tasks,
  .plan-day-card-content--scheduled-tasks {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .plan-day-card-content--scheduled-tasks {
    margin-bottom: 0;
  }

  .plan-day-card-content--due-tasks {
    border-radius: 0px;
  }

  .plan-day-card-content--due-tasks:not(.plan-day-card-content--due-tasks--total-0) {
    max-height: calc($taskInputHeight * 3 + 40px);
  }

  .my-plan-navigation {
    display: none !important;
  }

  .plan-day-card-inner-container {
    .plan-day-weeks-header-container {
      padding: 0;
      margin-bottom: 20px;

    }
  }

  .plan-scroll-container .plan-day-card-container.plan-day-card-container--display-type-3 {
    .plan-day-card-content--due-tasks:not(.plan-day-card-content--due-tasks--total-0) {
      max-height: calc($taskInputHeight * 3 + 60px);
    }
  }

  .plan-day-card-content--due-tasks.plan-day-card-content--due-tasks--total-0,
  .plan-day-card-content--scheduled-tasks.plan-day-card-content--scheduled-tasks--total-0 {
    .plan-day-card-empty-filter {
      padding: 0;
    }
  }

  .plan-day-card-container--display-type-1 {
    .plan-day-card-content {

      &--due-tasks,
      &--scheduled-tasks {
        .single-task-container {
          padding-bottom: 0 !important;

          .tasks-list-item {
            padding-bottom: 2px;
          }
        }
      }
    }
  }
}