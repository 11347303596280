@import '../../../styles/sharedStyle.index.scss';

.login-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4vh;

  button {
    position: relative;
    width: 220px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 0;
    white-space: nowrap;
    @include fontBody;

    .action-btn-img-container {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 4px;
      img {
        display: block;
      }
    }

    &.google-login,
    &.apple-login {
      @include themed(border-color, transparentColor);
      @include themed(background-color, buttonThirdParty);
      @include themed(color, textContrast);
      margin-top: 12px;
      justify-content: flex-start;
    }
    
    &.apple-login {
      .apple-logo * {
        @include themed(fill, textContrast);
      }
    }

    &.phone-login,
    &.email-login,
    &.discord-login {
      height: 18px;
      padding: 0;
      display: flex;
      justify-content: center;
      @include themed(background-color, backgroundTransparent);
      border: none;
      @include themed(color, textPrimary);
      text-decoration: underline;
      text-decoration-skip-ink: none;
      text-underline-position: from-font;
      @include fontFootnote;
      margin-top: 16px;

      .action-btn-img-container{
        width: 16px;
        height: 16px;
        svg * {
          @include themed(stroke, textPrimary);
        }
      }
    }

    &.discord-login {
      padding-left: 6px;
      img{
        max-width: 16px;
        filter: invert(1);
      }
    }

    &.email-login {
      margin-top: 6vh;
    }

    .loading-outlined {
      position: absolute;
      right: 6px;
    }
  }
}

@media only screen and (max-height: 660px) {
  .login-options{
    .flex-container{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 9px;
      margin-top: 3vh;
      button {
        margin: 0;
        width: auto;
      }
    }
  }
}

@media only screen and (min-width: 769px) and (max-height: 660px) {
  .login-options{
    .google-and-apple-flex-container {
      display: flex;
      justify-content: center;
      column-gap: 12px;
      margin-top: 3vh;
      button {
        margin: 0;
      }
    }
  }
}