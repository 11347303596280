@import '../../../../styles/mixin.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/layout.scss';

// shared mobile and desktop
.chat-history-container {
  width: 100%;
  z-index: $summariesOverlayZIndex;
  text-align: start;

  .summaries-list-scrollable-container {
    height: 100%;
    overflow-y: auto;

    &:has(.page-loader-animation),
    &:has(.reload-summaries),
    &:has(.no-summaries) {
      padding: 0;
      margin: 0;
      @include displayFlex(center, center, column);
    }

    .no-summaries,
    .reload-summaries,
    .reload-summaries span {
      font-family: Dosis !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.16px;
      @include themed(color, textPrimary);
    }

    .reload-summaries {
      text-align: center;
      span {
        display: block;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .summary-page-loader {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// mobile
@include mobileView {
  .chat-history-container {
    height: calc(100vw - $mobileHeaderHeight);
    height: calc(var(--availableViewportHeight) * 100 - $mobileHeaderHeight);
    position: fixed;
    top: auto;
    left: 0;
    .summaries-list-scrollable-container {
      padding: 20px;

      .page-loader-animation,
      .reload-summaries,
      .no-summaries {
        margin-top: calc($mobileHeaderHeight * -1);
      }
    }
  }
}

// desktop
@include desktopView {
  .chat-history-container {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .summaries-list-scrollable-container {
      padding: 33px 25px 40px $desktopHeaderInlinePadding;
      margin-right: $scrollbarMarginRight;
      @include designedScrollbar();

      .page-loader-animation,
      .reload-summaries,
      .no-summaries {
        margin-top: calc($desktopHeaderHeight * -1);
      }
    }
  }
}
