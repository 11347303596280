@mixin textTypographyWithoutColor(
  $fontSize,
  $fontWeight: normal,
  $letterSpacing: normal,
) {
  font-size: $fontSize;
  font-weight: $fontWeight;
  letter-spacing: $letterSpacing;
}

.large-title {
  font-family: 'Caprasimo' !important;
  @include textTypographyWithoutColor(32px, normal);
}

.title1,.title2 {
  font-family: 'Dosis' !important;
  font-weight: 500;
}

.title1 {
  font-size: 24px;
}

.title2 {
  font-size: 16px;
}

.footnote,.caption {
  font-family: 'Lato' !important;
  font-size: 12px;
  font-weight: 400;
}

.footnote {
  font-size: 12px;
}

.caption {
  font-size: 10px;
}

@mixin fontLargeTitle($letterSpacing: -0.16px, $fontWeight: normal) {
  font-family: 'Caprasimo' !important;
  @include textTypographyWithoutColor(32px, $fontWeight, $letterSpacing);
}

@mixin fontSmallTitle($letterSpacing: -0.16px, $fontWeight: normal) {
  font-family: 'Caprasimo' !important;
  @include textTypographyWithoutColor(24px, $fontWeight, $letterSpacing);
}

@mixin fontTitle1 {
  font-family: 'Dosis' !important;
  @include textTypographyWithoutColor(24px, 500);
}

@mixin fontTitle2 {
  font-family: 'Dosis' !important;
  @include textTypographyWithoutColor(16px, 500);
}

@mixin fontBody {
  font-family: 'Lato' !important;
  @include textTypographyWithoutColor(16px, 400);
}

@mixin fontFootnote {
  font-family: 'Lato' !important;
  @include textTypographyWithoutColor(12px, 400);
}

@mixin fontCaption($fontWeight: 400) {
  font-family: 'Lato' !important;
  @include textTypographyWithoutColor(10px, $fontWeight);
}