@import '../../../../../styles/sharedStyle.index.scss';

@mixin taskToastMessageContainer($toastMessageBottomPosition: 0px) {
  position: absolute;
  bottom: $toastMessageBottomPosition;
  margin: 0;
  margin-bottom: 20px;
  z-index: $stageTasksToastMsgrOverlayZIndex;
  padding: 0;
  width: fit-content;
  min-width: 40px;
}

.toast-messages-container-fade-container {
  position: relative;
  width: 100%;

  .message.bot,
  .message .message-content-container {
    max-width: 100%;
    width: 100% !important;
  }

  .message .message-content-container {
    .msg-content {
      padding: 30px 30px 15px 15px;
    }
  }
}

$spacingBetweenTextMsgToCloseButton: 5px;
$spacingTopRightCloseButton: 15px;
$toastMessageLeftDesktop: $stageContentPaddingLeftDesktop;

@mixin mobileModeStagingToastMessages($maxWidthBotToastMessage, $toastMessageBottomPosition: 0px) {
  .stage-container {
    .toast-messages-container {
      @include taskToastMessageContainer($toastMessageBottomPosition);
      max-width: $maxWidthBotToastMessage;

      &.fadeOut {
        animation-duration: 1s !important;
        -webkit-animation-duration: 1s !important;
      }

      .fadeIn {
        animation-duration: 250ms !important;
        -webkit-animation-duration: 250ms !important;
      }

      .lottie-message-animation {
        width: 100px;
        height: 100px;
        margin-bottom: -20px;
      }

      button.close-toast {
        position: absolute;
        top: 15px;
        right: 8px;
        @include transparencyBtn;
      }

      .message {
        display: block;
        width: fit-content;
        margin: 0;

        .message-content-container .msg-content {
          max-width: 100%;
          width: 100%;
        }
      }

      &:has(.message.reject-error) {
        .message.reject-error {
          .msg-content {
            width: 100%;
          }
        }
      }

      &:has(.dislike-feedback-container) {
        position: unset;
      }

      // additional visibility changes are controlled by the data-attributes-controls.scss file
      .lottie-message-animation-toast {
        display: none;
      }
    }
  }
}

.toast-messages-container button.close-toast {
  position: absolute;
  top: $spacingTopRightCloseButton;
  right: $spacingTopRightCloseButton;
  @include transparencyBtn;
  img {
    filter: invert(1) brightness(5);
  }
}

@include mobileView {
  $closeButtonSize: 15px;
  $toastMsgMaxWithMobile: calc(100% - 70px);
  @include mobileModeStagingToastMessages($toastMsgMaxWithMobile);

  .stage-container .toast-messages-container {
    left: 20px;

    button.close-toast {
      img {
        width: $closeButtonSize;
        height: $closeButtonSize;
      }
    }

    &:has(.message.reject-error) {
      max-width: 316px !important;
    }

    .message .msg-content {
      padding-top: 30px !important;
    }
  }

  body:has(.time-picker-context-container),
  body:has(.plan-tasks-placement-list-container--placing):not(:has(.plan-tasks-placement-list-container--full)) {
    .stage-container .toast-messages-container {
      bottom: $overlayMobileHeightWhenPlacingTask;
      margin-bottom: 10px !important;
    }
  }
}

@include desktopView {
  $closeButtonSize: 15px;

  .toast-messages-container {
    left: $toastMessageLeftDesktop;

    .message .msg-content {
      padding-top: calc(
        $spacingBetweenTextMsgToCloseButton + $closeButtonSize + $spacingTopRightCloseButton
      ) !important;
    }
  }

  .toast-messages-container button.close-toast {
    img {
      width: $closeButtonSize;
      height: $closeButtonSize;
    }
  }
}

.toast-on-stage,
.toast-messages-container,
.toast-messages-container-fade-container {
  &:has(.dislike-feedback-container) {
    position: unset !important;
    overflow: unset !important;
  }
}
