@import '../../../../../../../styles/sharedStyle.index.scss';

.external-source-details {
  @include displayFlex(flex-end, center);
  flex-wrap: wrap;

  &--title {
    display: block;
    @include textTypography(10px, 400, 0.1px);
    @include themed(color, textSecondary);
  }

  &--name {
    @include displayFlex(center, center);
    @include textTypography(7px, 400);
    width: 55px;
    border-radius: 2.5px;
    @include themed(border, borderPrimary, 0.5px solid );
    @include themed(background-color, backgroundPrimary);
    @include themed(color, textPrimary);
    padding: 0px 7.5px 0px 5px;
    white-space: nowrap;
    cursor: default;

    .source-icon {
      margin-right: 2.5px;
    }

    .apple-icon {
      width: 10px;
      margin-right: 5px;
    }
  }
}

@include mobileView {
  .external-source-details {
    gap: 8px;

    &--name {
      height: 12.5px;

      .source-icon {
        width: 12.5px;
        height: 12.5px;
      }

      .apple-icon {
        width: 8px;
        margin-right: 5px;
      }
    }
  }
}

@include desktopView {
  .external-source-details {
    gap: 15px;

    &--name {
      height: 20px;
    }
  }
}
