@import '../../../../../styles/sharedStyle.index.scss';

.details-dialog-animation-container#work-block-details {
  z-index: $WorkBlockFormOverlayZIndex !important;
}

@mixin submitOrSwitchButtonPosition {
  position: absolute;
  top: 20px;
  right: 20px;
}

@include mobileView {
  .work-block-dialog-container {
    &--create {
      button.close-dialog {
        svg path {
          stroke-width: 1;
        }
      }
    }
  }
}
