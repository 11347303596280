@import '../../../styles/mixin.scss';
@import '../../../styles/colors.scss';

.text-and-toggle-container {
  width: 100%;
  @include displayFlex(space-between, center);
}

.text-and-toggle-icon-and-header-container {
  display: flex;
  align-items: center;
}

.text-and-toggle-icon {
  font-size: 13px;
  margin-right: 2px;
  height: 20px;
}

.text-and-toggle-header-text {
  @include fontBody;
  line-height: 18px;
  @include themed(color, textPrimary);
}

.text-and-toggle-sub-header {
  @include fontCaption;
  @include themed(color, textPrimary);
  white-space: break-spaces;
}

.text-and-toggle-switch-container {
  @include displayFlex(space-between, center);
  column-gap: 15px;
}
