@import '../../../../../../styles/sharedStyle.index.scss';

.theme-control-button {
  @include displayFlex(center,center);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  @include themed(background-color, backgroundBrandMediumContrast);
  cursor: pointer;
  
  &, &--icon *{
    transition: all 0.3s ease;
  }
  
  &--active {
    @include themed(background-color, brandSecondary);
  }
}