@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';

.wait-list-page {
  width: 100vw;
  height: 100vh;
  height: calc(var(--availableViewportHeight) * 100);
  position: absolute;
  top: 0;
}

.wait-list-content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.wait-list-title {
  font-weight: 500;
  font-style: normal;
}

.wait-list-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 29px;
}

.wait-list-paragraph {
  width: 255px;
  white-space: pre-line;
}

@include desktopView {
  .wait-list-title {
    margin-top: 30px;
    margin-bottom: 4vh;
    font-size: 36px;
  }

  .wait-list-email {
    font-size: 16px;
    letter-spacing: 0.16px;
    margin-top: 4vh;
  }
}

@include mobileView {
  .wait-list-title {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 4vh;
  }

  .wait-list-paragraph {
    width: 260px;
  }

  .wait-list-email {
    font-size: 14px;
    letter-spacing: 0.14px;
    margin-top: 4vh;
  }
}
