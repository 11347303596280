@import '../../../../../styles/layout.scss';

$tasksFiltersOpenHeightDesktop: 230px;
$tasksFiltersOpenHeightMobile: 225px;
$tasksFiltersTransitionDuration: 0.4s;

$taskOverlaysRightPosition: calc(
  15px + $scrollbarMarginRight + $chatAndStageContainerPaddingRightLeft
);

// export the tasks SASS variable to be used in a .tsx file
:export {
  taskOverlaysRightPosition: $taskOverlaysRightPosition;
}