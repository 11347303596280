@import '../../../styles/typography.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

.input-container{
  width: 100%;
  @include displayFlex(flex-start, center, column);
  
  .input-error {
    text-align: center;
    line-height: 16px;
    @include themed(color, colorStateError);
    white-space: nowrap;
    @include fontCaption;
  }
}

.app-input, .app-phone-input, .app-select-container, .app-textarea {
  @include fontBody;
  padding: 8px 12px;
  text-align: left;
  width: 220px;
  height: 40px;
  border-radius: 8px;
  @include themed(border, borderInput, 1px solid);
  @include themed(background-color, backgroundPrimary);
  @include themed(color, textPrimary);

  // Figma-Enabled
  /* Styles when the input is empty (placeholder is shown) */
  &:placeholder-shown {
    @include themed(border-color, borderInput);
  }

  // Figma-Activated
  /* Styles when the input has content */
  &:not(:placeholder-shown), :-webkit-autofill{
    // gradient border
    @include appGradientBorderForInputElement;
  }

  &::placeholder {
    @include themed(color, textPlaceholder);
    @include fontBody;
  }

  // Figma-Focused
  &:focus, &--open {
    outline: none;
    @include themed(border-color, borderInputFocused, null, !important);
    @include themed(background-color, backgroundPrimary);
  }

  // Figma-Disabled
  &:disabled {
    @include themed(background-color, buttonPrimaryDisabled);
    @include themed(border-color, transparentColor);

    &::placeholder{
      @include themed(color, transparentColor);
    }
  }

  // Figma-Error
  &--error {
    @include themed(border-color, borderInputError, null, !important);
    @include themed(background-color, backgroundPrimary, null, !important);
  }
}

.app-select-container {
  text-align: center;
  padding-right: 0;
  padding-left: 0;
}
