@import '../../../../../../styles/mixin.scss';
@import '../../../../../../styles/colors.scss';

.single-summary {
  cursor: pointer;

  .summary-time {
    @include fontCaption;
    width: 50px;
    line-height: 16px;
    padding: 0 5px;
    height: 16px;
    @include themed(background-color, backgroundBrandLowContrast);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include themed(color, textPrimary);
    margin-bottom: 10px
  }

  .summary-name {
    @include fontBody;
    @include themed(color, textPrimary);
  }

  .summary-not-available {
    @include fontBody;
    @include themed(color, textNotSet);
  }
}