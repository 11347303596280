@import '../../../../../../styles/sharedStyle.index.scss';

.selected-filters-container{
  @include displayFlex(flex-start, center, row, wrap);
  gap: 8px;
  margin-bottom: 12px;

  &.no-filter-selected {
    margin-bottom: 0px;
  }

  .single-tag {
    margin: 0;
  }
}

@include desktopView {
  .selected-filters-container {
    margin-right: $scrollbarMarginRight;
  }
}