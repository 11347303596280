$font-2px: 2px;
$font-10px: 10px;
$font-13px: 13px;
$font-14px: 14px;
$font-15px: 15px;
$font-16px: 16px;
$font-18px: 18px;
$font-19px: 19px;
$font-22px: 22px;
$font-26px: 26px;
$font-30px: 30px;
$font-32px: 32px;
$font-128px: 128px;
