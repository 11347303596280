@import '../../../styles/sharedStyle.index.scss';

.timer-page-info-dialog {
  padding: 48px 24px 24px;
  display: flex;
  width: 350px;
  max-width: 100%;
  height: 232px;
  @include displayFlex(flex-start, flex-start, column);
  position: relative;
  margin-bottom: 20px;

  .timer-page-info-dialog-mascot {
    height: auto;
    position: absolute;
    top: 25px;
    right: 24px;
  }

  .timer-page-info-dialog-header {
    @include fontSmallTitle;
    padding-bottom: 40px;
  }

  .timer-page-info-dialog-description {
    @include themed(color, textPrimary);
    width: 95%;
  }

  .timer-page-info-dialog-link {
    @include fontFootnote;
    padding-top: 24px;
  }

  .timer-page-info-dialog-header,
  .timer-page-info-dialog-link {
    @include themed(color, textBrand);
  }
}

@include mobileView {
  .timer-page-info-dialog {
    align-self: center;
  }
}

@include desktopView {
  .timer-page-info-dialog {
    align-self: flex-start;
  }
}