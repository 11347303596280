@import '../../../styles/sharedStyle.index.scss';

.edit-button {
  @include displayFlex(flex-end, center);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  text-decoration-line: underline;
  color: $neutral400; // will remove at the settings view changes
  margin-right: 15px;
  border: transparent;
  @include themed(background-color, backgroundTransparent);
  padding: 0;

  img {
    margin-right: 5px;
  }
}

@include mobileView {
  .edit-button {
    margin-right: 10px;
  }
}
