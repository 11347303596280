@import '../../../../../styles/sharedStyle.index.scss';

.important-links {
  margin-bottom: 40px;
  a {
    display: block;
    margin-bottom: 14px;
    @include fontFootnote;
    line-height: 18px;
    @include themed(color, textPrimary);
    text-decoration: underline;
    cursor: pointer;
    width: fit-content;
  }
}
