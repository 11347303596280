@import '../../../styles/sharedStyle.index.scss';

.app-empty-state {
  @include displayFlex(space-between, flex-end);
  width: 100%;
  position: absolute;
  gap: 20px;

  &--paragraph {
    @include fontBody;
    @include themed(color, textPrimary);
    padding-bottom: 4px;
  }
  &--mascot {
    min-width: 100px;
  }
}

@include mobileView {
  .app-empty-state {
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 30px;
    padding: 0 $mobileScreenEdgeSpacing;

    &--paragraph {
      white-space: pre-line;
    }
  }
}

@include desktopView {
  .app-empty-state {
    left: 0;
    bottom: 47px;
    padding: 0 30px 0 40px;

    &--paragraph {
      height: 70px;
      @include displayFlex(center, flex-end);
    }
  }
}
