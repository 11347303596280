@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixin.scss';
@import '../../../../../styles/themes/theme-mixins.scss';

$submitHeight: 30px;

.settings-feedback {
  width: 100%;

  form {
    width: 100%;
    text-align: left;
    @include displayFlex(flex-start, flex-start, column);
    font-size: 14px;
    font-weight: 400;

    label {
      @include fontFootnote;
      display: block;
      margin-bottom: 22px;
      line-height: 18px;
      @include themed(color, textPrimary);
    }

    .error-relative {
      position: relative;
      height: fit-content;
      width: 100%;
      margin-bottom: 10px;

      .app-textarea {
        width: 100%;
        height: 180px;
        line-height: 24px;
        resize: none;
        @include appGradientBorderForInputElement;
        
        &:focus {
          @include appGradientBorderForInputElement;
          outline: none;
        }
      }

      .update-settings-error {
        bottom: 16px;
        left: 10px;
      }
    }

    .email-and-submit-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      column-gap: 10px;

      section {
        @include displayFlex(flex-start, center);
        column-gap: 10px;
      }

      .email-input {
        height: $submitHeight;
        width: 100%;
        @include themed(border, none);
        border-radius: 10px;
        @include themed(background-color, backgroundBrandLowContrast);
        @include fontBody;
        @include themed(color, textPrimary);
        padding: 0 10px;
        padding-bottom: 1px;

        &::placeholder {
          @include fontBody;
        }
        &:focus {
          outline: none;
        }
      }

      .switch-email-text {
        @include fontCaption;
        @include themed(color, textPrimary);
        margin-left: 52px;
        height: 24px;
        display: flex;
        align-items: center;
      }
    }

    button.submit-feedback {
      width: 81px;
      height: $submitHeight;
      padding: 0;
      letter-spacing: 0.12px;
      @include fontFootnote;

      &:disabled {
        opacity: 0.6;
      }
    }

    .email-switch-section {
      width: 100%;
    }
  }
}

@include desktopView {
  .settings-feedback {
    form {
      label {
        margin-bottom: 28px;
      }

      .error-relative {
        margin-bottom: 24px;

        .app-textarea {
          height: 190px;
        }
      }
    }
  }
}

@media screen and (max-width: 660px) {
  .settings-feedback {
    form {
      .email-and-submit-container {
        section.email-switch-section {
          position: relative;

          .switch-email-text {
            position: absolute;
            bottom: -20px;
            left: 0;
            display: block;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
