@import '../../../../../../styles/sharedStyle.index.scss';
@import './singleTaskItemContainer.module.scss';

$checkboxDefaultColor: $tagUntagged;
$checkboxSize: 18px;
$borderBottomWidth: 1px;

.single-task-container{
  overflow: hidden;
  height: calc($singleTaskHightStageTasks + $borderBottomWidth);

  &.shrink-animation {
    animation: shrinkAnimation 0.5s forwards;
  }
}

.tasks-list-item {
  width: 100%;
  height: $singleTaskHightStageTasks;
  @include displayFlex(space-between, center);
  padding: 0px;
  @include themed(background-color, backgroundTransparent);
  margin-bottom: 0px;
  @include themed(border-bottom, borderSecondary, $borderBottomWidth solid);
  position: relative;
  cursor: pointer;

  .task-item-left {
    @include displayFlex(flex-start, center);
    padding-left: 0px;
    flex: 1;
    
    label.task-complete-label {
      padding-left: calc($checkboxSize + 5px);
      position: relative;
      @include displayFlex(flex-start, center);
      width: 18px;
      height: 18px;
      min-height: 24px;
      // var(--task-checkbox-color): Define a CSS variable to set the checkbox color outside this file on the .tsx file
      @include appCheckboxUsingBeforeAndAfter(var(--task-checkbox-color, $checkboxDefaultColor), $checkboxSize);
    }

    .task-name-container {
      @include displayFlex(flex-start, center);
      max-width: 75%;

      span.task-name, span.external-event-source {
        display: block;
        margin-left: 12px;
        margin-right: 0px;
        @include themed(color, textPrimary);
        @include fontBody;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: start;

        img {
          width: 10px;
          height: 10px;
          margin-right: 3px;
          margin-bottom: -1px;
        }
      }
    }

    .task-subtasks-chevron-btn {
      @include transparencyBtn;
      margin-right: 8px;
      margin-left: 6px;
      .task-chevron{
        transition: 0.3s;

      }
      .task-chevron--expanded {
        transform: rotate(-180deg);
      }
    }

    .quick-win-icon-container { 
      margin-left: 6px;
      @include displayFlex(center, center);
      .quick-win-icon * {
        @include themed(fill, textPrimary);
      }
    }
  }

  .task-item-right {
    @include displayFlex(flex-end, center);

    .task-work-time-range {
      @include fontFootnote;
      @include themed(color, textPrimary);
      margin-left: 4px;
      white-space: nowrap;
    }

    .task-actions-container {
      @include displayFlex(flex-end, center);
    }

    button.priority {
      @include transparencyBtn;
      margin-left: 10px;

      .single-task-priority-icon {
        width: 20px;
        height: 20px;

        path {
          transition: all 0.3s ease-in-out;
        }

        &--on path {
          @include themed(fill, textPrimary);
        }

        &--off path {
          @include themed(fill, textBrandContrast);
        }
      }
    }

    .task-icons-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      margin-top: 2px;

      >*:not(:last-child) {
        margin-right: 6px;
      }

      &:not(:has(*)) {
        height: 10px;
      }

      .due-date-tag {
        min-width: 40px;
        height: 16px;
        padding: 0 8px 1px;
        @include displayFlex(center, center);
        @include themed(color, textContrast);
        @include themed(background-color, backgroundBrandInvert);
        border-radius: 20px;
        @include fontCaption(700);
        white-space: nowrap;

        &--overdue {
          @include themed(background-color, colorStateError);
          @include themed(color, textBrandHighContrast);
          padding-bottom: 0px;
          padding-top: 1px;
        }
      }
    }
  }

  &:has(input.complete-checkbox[type='checkbox']:checked),
  &.completed-task {
    .task-item-left span.task-name {
      text-decoration: line-through;
    }
  }

  &--event {
    $eventTitleIconWrapperSize: 20px;

    .task-item-left {
      .event-icon-circle-background {
        $circleBgColor: var(--task-checkbox-color, transparent);
        @include fullCircle($eventTitleIconWrapperSize, $circleBgColor);
      }
    }
  }
}

@include desktopView {
  $numberOfActionsButtons: 3;
  $distanceBetweenLeftToRight: 12px;
  $distanceBetweenActionButtonsToStarIcon: 12px;
  $actionButtonsContainerRightPosition: 15px;
  .tasks-list-item {
    .task-item-left {
      max-width: 70%;  
      label.task-complete-label {

        // accessibility - add outline when focusing with keyboard tabbing only
        &:has(input[type='checkbox']:focus) {
          &::before {
            @include themed(outline, textPrimary, solid);
          }
        }

        // accessibility
        &:has(input[type='checkbox']:focus:not(:focus-visible)) {
          &::before {
            outline: none;
          }
        }
      }

      .task-subtasks-chevron-btn {
        margin-left: 4px;
      }
    }

    &:has(.task-actions-container) {
      .task-item-left {
        max-width: calc(100% - $numberOfActionsButtons * ($singleTaskActionButtonWidth * 1px + $distanceBetweenBetweenEachButton) - $distanceBetweenLeftToRight - $distanceBetweenActionButtonsToStarIcon - $actionButtonsContainerRightPosition);
      }
    }

    .task-item-right {
      position: relative;

      .task-actions-container {
        position: absolute;
        right: $actionButtonsContainerRightPosition;
        padding-right: $distanceBetweenActionButtonsToStarIcon;
      }

      .task-action-button {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      button.priority {
        margin-left: 10px;
      }
    }

    &:hover {
      .task-item-right .task-action-button {
        opacity: 1;
      }

      &:has(.task-actions-container) {
        .task-icons-container {
          visibility: hidden;
        }
      }
    }
  }
}

// hide desktop tasks in mobile and tablets
@mixin hideDesktopTasks {
  .tasks-list-item {
    &.single-task-desktop-view,
    .task-actions-container {
      display: none !important;
    }
    &:hover {
      .task-icons-container {
        visibility: visible !important;
      }
    }
  }
}

@include mobileView {
  @include hideDesktopTasks;
  .tasks-list-item {
    padding-top: 5px;

    .task-item-left {
      max-width: 70%;
    }
  }
}

@include TabletsAndIPadsView {
  @include hideDesktopTasks;
}

.single-task-container-mobile {
  @include hideDesktopTasks;
}

body:has(.dislike-feedback-container) {
  .tasks-list-item ,.swipeable-item-container .swipeable-item{
    position: unset !important;
  }
}

@keyframes shrinkAnimation {
  from {
    height: $singleTaskHightStageTasks;
  }
  to {
    height: 0;
  }
}
