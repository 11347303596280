@import '../../../../../styles/sharedStyle.index.scss';
@import './tasks-list-single-item/singleTaskItemContainer.module.scss';
@import './StageTasksVariables.module.scss';

.stage-tasks-container {
  height: 100%;
  width: 100%;
  @include themed(background-color, backgroundTransparent);
  @include displayFlex(flex-start, flex-start, column);
  text-align: start;
  transition: padding-top $tasksFiltersTransitionDuration;

  .scrollable-container {
    flex: 1;
    overflow-y: auto;
    @include designedScrollbar(
      $scrollbarTopDistance,
      $scrollbarBottomDistance + $desktopChatFormInputHeight
    );

    &:has(.tasks-list-item:not(.subtask-item)) {
      .stage-tasks-empty-state {
        display: none;
      }
    }

    &:not(:has(.tasks-list-item:not(.subtask-item))) {
      .stage-tasks-empty-state {
        display: flex;
      }
    }

    .stage-tasks-empty-state {
      .app-empty-state--paragraph {
        white-space: pre-line;
      }
    }
  }

  input.add-main-task-input {
    height: 30px;
    border-radius: 5px;
  }
}

.resizable-container:has(.stage-tasks-dropdown-filter.open) {
  .stage-tasks-container .selected-filters-container {
    display: none;
  }
}

@include mobileView {
  $tasksWrapperPaddignTop: 10px;
  .stage-tasks-container {
    padding: $tasksWrapperPaddignTop $mobileScrollbarSpaceFromRigthEdge 0 $mobileScreenEdgeSpacing;

    > div {
      padding-right: calc($mobileScreenEdgeSpacing - $mobileScrollbarSpaceFromRigthEdge);
    }
    .scrollable-container {
      width: 100%;
      padding: 0;
      @include themed(background-color, backgroundPrimary);
      border-radius: 8px 8px 0px 0px;

      .swipeable-item-container {
        height: $singleTaskHightStageTasks !important;
      }

      .swipeable-item-right-side .task-action-button {
        border-left: none;
      }

      .swipeable-item-left-side .task-action-button {
        border-right: none;
      }
    }

    input.add-main-task-input {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .resizable-container:has(.stage-tasks-dropdown-filter.open) {
    .stage-tasks-container {
      padding-top: calc($tasksWrapperPaddignTop + $tasksFiltersOpenHeightMobile - $mobileHeaderHeight);
    }
  }
}

@include desktopView {
  $scrollablePaddingRight: calc($stageContentPaddingRightDesktop - $scrollbarMarginRight - $desktopAppScrollbarWidth);
  $tasksWrapperPaddignTopBottom: 42px;
  .stage-tasks-container {
    padding: $tasksWrapperPaddignTopBottom $scrollablePaddingRight $tasksWrapperPaddignTopBottom $stageContentPaddingLeftDesktop;

    .scrollable-container {
      width: 100%;
      padding-right: $scrollbarMarginRight;
      overflow-x: hidden;
      @include designedScrollbar($scrollbarTopDistance + 30px);
    }

    input.add-main-task-input {
      margin-top: 11px;
    }
    .stage-tasks-empty-state {
      padding: 0 $scrollablePaddingRight 0 $stageContentPaddingLeftDesktop;
    }
  }

  .resizable-container:has(.stage-tasks-dropdown-filter.open) {
    .stage-tasks-container {
      padding-top: calc($tasksWrapperPaddignTopBottom + $tasksFiltersOpenHeightDesktop - $desktopHeaderHeight);
    }
  }

  .display-stage.stage-expanded .stage-tasks-container {
    max-width: calc($stageContentMaxWidthOnExpendMode + ($scrollbarMarginRight * 2));
    margin: 0 auto;
    padding-left: $scrollbarMarginRight;
    padding-right: 0;
    .scrollable-container {
      padding-right: $scrollbarMarginRight;
      margin: 0 auto;

      .stage-tasks-empty-state {
        left: 50%;
        transform: translateX(-50%);
        padding: 0 $scrollbarMarginRight 0 $scrollbarMarginRight;
        max-width: calc($stageContentMaxWidthOnExpendMode + ($scrollbarMarginRight * 2));
        margin: 0 auto;
      }
    }
  }
}
