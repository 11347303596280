@import '../../../styles/layout.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';
@import '../../../styles/themes/theme-mixins.scss';

.app-modals-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: var(--offset-h, 0);
  width: 100vw;
  height: 100vh;
  height: calc(var(--availableViewportHeight) * 100);
  z-index: $settingsOverlayZIndex;
  padding-top: 30px;
  @include themed(background, gradientBackground);
  background-size: cover;

  .app-modals-content-frame {
    padding: 0;

    > div {
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }

  .title {
    font-family: 'Dosis' !important;
    @include fontStyle();
    @include fontWeight(500);
    @include lineHeight();
  }
}

// mobile
@include mobileView {
  .app-modals-container {
    .app-modals-content-frame {
      overflow: visible;
    }

    &:has(.settings-menu) {
      height: calc(100vw - $mobileHeaderHeight);
      height: calc(var(--availableViewportHeight) * 100 - $mobileHeaderHeight);
      margin-top: calc($mobileHeaderHeight - 1px);
      padding: 0;
      align-items: flex-start;

      .app-modals-content-frame {
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        border: none;
      }
    }

    .title {
      @include fontSize(24px);
      @include letterSpacing(0.24px);
    }
  }
}

// desktop
@include desktopView {
  .app-modals-container {
    &:has(.settings-menu) {
      width: calc(100vw - $desktopNavbarWidth) !important;
      right: 0;
      left: auto;
      padding-top: 0;
      align-items: flex-start;

      .app-modals-content-frame {
        width: 48%;
        height: 100%;
        margin-top: 0;
        border-radius: 15px 15px 0px 0px;
        box-shadow: none;
        border: none;
      }
    }

    .title {
      @include fontSize(40px);
      @include letterSpacing(0.4px);
    }
  }
}
