@import '../../../../../styles/themes/theme-mixins.scss';

.download-checkpoint {
  margin-bottom: 20px;

  .download-checkpoint-btn {
    display: block;
    height: 40px;
    width: 150px;
    font-size: 13px;
  }

  .error {
    @include themed(color, colorStateError);
    font-size: 12px;
  }
}
