@import "../../../styles/sharedStyle.index.scss";

.app-checkbox {
  $checkboxColor: var(--checkboxColor, $tagUntagged);
  @include appCheckboxUsingBeforeAndAfter($checkboxColor, var(--checkboxSize), var(--checkMarkSize));
  padding-left: calc(var(--checkboxSize) + var(--marginRightSpace));

  &::before,
  &::after {
    border-width: var(--checkboxBorderWidth);
  }
}