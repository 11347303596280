@import './CalendarDay.module.scss';
@import '../../../../styles/sharedStyle.index.scss';

$eventTitleIconWrapperSize: 20px;

.calendar-event {
  cursor: pointer;
  user-select: none;
  /* Prevents text selection while dragging an element in mobile */

  .calendar-event-inner-container {
    $taskCheckboxSize: var(--calendar-task-checkbox-size, 12px);

    height: calc(100% - 1px);
    border-radius: 8px;
    overflow: hidden;
    @include displayFlex(space-between, flex-start, column, wrap);
    @include themed(background-color, backgroundBrandLowContrast);
    @include themed(color, textPrimary);

    &.focused {
      @include themed(box-shadow, borderPrimary, 0px 4px 0px 0px);
    }

    .event-text-container {
      @include displayFlex(flex-start, flex-start, column);
      width: 98%;
      flex: 1;
      overflow: hidden;

      .calendar-event-addition-text {
        @include themed(color, textSecondary);
        @include fontCaption;
        white-space: nowrap;
        height: 16px;
      }
    }

    .calendar-event-title {
      width: 100%;
      @include fontFootnote;
      @include displayFlex(flex-start, flex-start);
      position: relative;

      &--event {
        min-height: 20px;
      }

      &.completed-task {
        text-decoration: line-through !important;
      }

      .task-circle-icon {
        width: $eventTitleIconWrapperSize;
        min-width: $eventTitleIconWrapperSize;

        * {
          stroke: var(--calendar-event-first-label-color, $tagUntagged);
        }
      }

      .event-icon-circle-background {
        $circleBgColor: var(--calendar-event-first-label-color, transparent);
        @include fullCircle($eventTitleIconWrapperSize, $circleBgColor);
        position: absolute;
        left: 0;
      }

      .title-text {
        @include fontFootnote;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .app-checkbox.task-checkbox {
        &::before,
        &::after {
          bottom: unset;
          top: 0;
          transform: unset;
        }
      }
    }

    .calendar-event-addition-info-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 11px;
      width: 100%;
    }

    .calendar-event-addition-text {
      width: 90%;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // my-day calendar event
    &--day {
      $titleColumnGap: 11px;
      padding: $dayCalendarEventPaddingTopBottom $dayCalendarEventPaddingRightLeft;

      .calendar-event-title {
        column-gap: $titleColumnGap;
        align-items: center;

        &--event {
          padding-left: calc($eventTitleIconWrapperSize + $titleColumnGap);
        }

        .app-checkbox.task-checkbox {
          padding-left: calc($taskCheckboxSize + $titleColumnGap);
        }

        .title-text {
          display: block;
          white-space: nowrap;
        }
      }
    }

    // my-week calendar event
    &--week {
      $titleColumnGap: 8px;
      padding: $weekCalendarEventPaddingTopBottom $weekCalendarEventPaddingRightLeft;
      .calendar-event-title {
        column-gap: $titleColumnGap;

        &--event {
          padding-left: calc($eventTitleIconWrapperSize + 4px);

          &:has(.event-icon--tagged) {
            padding-left: calc($eventTitleIconWrapperSize + $titleColumnGap);
          }
        }

        .app-checkbox.task-checkbox {
          padding-left: calc($taskCheckboxSize + $titleColumnGap);
        }

        .title-text {
          line-height: $calendarEventTitleLineHeight;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal; /* Allow text to wrap */
          height: 100%;
        }
      }
    }

    &--over-30 {
      .calendar-event-addition-text {
        margin-top: 6px;
      }
    }

    &--30 {
      padding-top: 4px;
      padding-bottom: 0;
      // Hide focus mode button in 30 min event
      .focus-mode-button{
        display: none;
      }
      .calendar-event-title {
        .title-text {
          margin-top: 2px;
        }
      }
    }
  }

  &.resizing {
    cursor: ns-resize;
    /* Change cursor during resizing */
    z-index: $calendarEventWhileResizingZIndex;

    &.event-or-task .calendar-event-inner-container {
      align-items: flex-start;
    }
  }

  &--work-block {
    .calendar-event-inner-container {
      .event-text-container {
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        overflow: hidden;
      }
    }
  }
}
