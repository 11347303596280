@import '../../../../styles/sharedStyle.index.scss';
@import '../../../../styles/mixin.scss';

$dropdownItemsMarginRight: 15px;
$dropdownMinWidth: 88px;
$dropdownToggleButtonHeight: 19px;
$copyLinkButtonHorizontalPadding: 48px;
$toastTopOffset: 12px;

.timer-share-button-container {
  position: relative;
  display: inline-block;
  flex: 0 0 auto;

  &--hidden {
    visibility: hidden;
  }

  @include mobileView {
    left: 0px;
  }

  .timer-share-error {
    position: absolute;
    top: calc(100% + $toastTopOffset);
    right: 0;
    padding: 16px;
    border-radius: 8px;
    @include themed(background-color, backgroundBrandSecondary);
    @include themed(color, textPrimary);
    z-index: 1001;
    white-space: nowrap;

    @include mobileView {
      padding: 12px 16px;
    }
  }

  .timer-share-button {
    @include displayFlex(space-between, center);
    @include fontBody;
    @include themed(border, transparent);
    @include themed(background-color, brandPrimary);
    @include themed(color, textInvert);
    border-radius: 5px;
    white-space: nowrap;
    padding: 4px 12px;
    min-width: 108px;
    height: 35px;

    .timer-share-icon {
      margin-left: 8px;
      stroke: primitiveColor(neutral100);
      width: 16px;
      height: 16px;
    }
    &--copied {
      @include themed(background-color, buttonPrimaryFocus);
    }
  }
}

@include mobileView {
  .plan-view-selection {
    display: none;
    margin-top: 2px;
  }

  body {
    &:has(.display-stage .chat .stage-tasks-container),
    &:has(.display-stage .chat .stage-planner-container) {
      .plan-view-selection {
        display: inline-block;
      }
    }

    &:has(.app-modals-container .settings-menu) {
      .plan-view-selection {
        display: none !important;
      }
    }
  }
}

// hide dropdown when message dislike feedback modal is open
body {
  &:has(.chat .message .dislike-feedback-container) {
    .plan-view-selection {
      display: none !important;
    }
  }
}
