@import '../../../../../styles/colors.scss';
@import '../../../../../styles/themes/theme-mixins.scss';

.update-settings-error {
  position: absolute;
  bottom: -15px;
  right: 0;
  @include themed(color, colorStateError, null, !important);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.12px;
  &--static-position {
    position: static;
  }
}
