@import '../../../../styles/sharedStyle.index.scss';
@import './ChatFormUserInput.module.scss';

.chat-bar-form {
  width: 100%;
  @include displayFlex(space-between, flex-start);
  flex-wrap: nowrap;
  @include themed(background-color, backgroundPrimary);
  padding: 6px 20px;
  border-radius: 8px;
  // gradient border
  @include appGradientBorderForInputElement;

  section {
    width: 100%;
    @include displayFlex(space-between, flex-end);
    flex-wrap: nowrap;
    @include themed(background-color, backgroundTransparent);
    margin: 0 !important;
    padding: 0 !important;

    &:has(button.cancel-recording){
      align-items: center;
    }

    textarea {
      width: 93%;
      // max 5 lines
      max-height: $chatBarTextAreaMaxHeightMobile;
      @include themed(border, none);
      @include themed(background-color, backgroundTransparent);
      @include themed(color, textPrimary);
      padding: 0px;
      resize: none;
      margin: 1px 0 0 0 !important;
      padding-right: 12px;
      @include fontBody;
      line-height: 24px;
      align-self: center !important;

      &::-webkit-scrollbar {
        width: 0px;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        cursor: default;
      }

      &:placeholder-shown {
        height: 22px !important;
      }
    }

    button.cancel-recording {
      @include transparencyBtn;
      @include fontFootnote();
      @include themed(color, textPrimary);
      padding: 0;
      text-decoration-line: underline;
      text-underline-offset: 2px;
      margin-right: 20px;
    }

    button.chat-form-submit {
      height: auto;
      width: auto;
      @include themed(border, none);
      @include themed(background-color, backgroundTransparent);
      @include displayFlex(center, center);
      padding: 0;
      margin: 0 !important;

      &--disabled {
        opacity: 0.5;
      }

      svg.chat-bar-icon * {
        @include themed(stroke, textPrimary);
      }

      .mic-button {
        cursor: pointer;

        &--loading {
          animation: micRotation 500ms infinite;
        }
      }
    }
  }
}

@include mobileView {
  .chat-bar-form {
    height: $mobileChatFormInputHeight;
    width: calc(100% - ($mobileScreenEdgeSpacing * 2));
    left: 50%;
    transform: translate(-50%, 0px);
    position: fixed;
    bottom: calc(var(--offset-h, 0) + $mobileChatBarFormBottomSpace + $mobileNavbarHeight);
    z-index: $summariesOverlayZIndex !important;
    align-items: flex-start;

    &:has(.send-button) {
      height: auto;
      min-height: $mobileChatFormInputHeight;
    }

    section {
      textarea {
        height: 20px;
        min-height: 20px !important;
        align-self: flex-start !important;
      }

      button.cancel-recording {
        margin-right: 12px;
      }
    }

    .confirm-modal {
      max-width: 350px;
      top: 15px;
      padding-right: 30px;
      padding-left: 30px;

      .confirm-modal-footer button {
        width: 100px;
      }
    }
  }
}

@include desktopView {
  .chat-bar-form {
    width: calc(100% - 40px);
    margin: 0 auto;
    margin-bottom: $chatBarMarginBottom;
    height: $desktopChatFormInputHeight;
    min-height: $desktopChatFormInputHeight;
    align-items: center;

    &:has(.send-button) {
      height: auto;
      min-height: $desktopChatFormInputHeight;
    }

    section {
      height: 100%;

      textarea {
        height: 22px;
        min-height: 22px !important;
      }
    }

    .confirm-modal {
      max-width: 455px;
      top: 20px;
      padding-right: 40px;
      padding-left: 40px;

      .confirm-modal-footer button {
        width: 130px;
      }
    }
  }
}

@keyframes micRotation {
  0% {
    transform: rotateY(0deg);
  }

  25% {
    transform: rotateY(50deg);
  }

  50% {
    transform: rotateY(150deg);
  }

  75% {
    transform: rotateY(200deg);
  }

  90% {
    transform: rotateY(250deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

body:has(.chat-container .dislike-feedback-container) {
  .chat-bar-form {
    display: none !important;
  }
}