@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';
@import '../../../styles/layout.scss';
@import '../../../styles/typography.scss';

.auth-layout {
  .back-link {
    width: 74px;
    height: 27px;
    @include displayFlex(center, center);
    font-style: normal;
    line-height: normal;
    text-decoration-line: underline;
    text-align: center;
    margin: 0 auto;
    @include themed(color, textPrimary);
    cursor: pointer;
    text-underline-offset: 2px;
  }

  button.back-link {
    @include transparencyBtn;
  }

  .auth-screen {
    flex-direction: column;
  }

  .phone-verification-page,
  .add-phone-registration-page,
  .login-with-phone-page,
  .login-with-email-page,
  .login-error-page {
    width: 100vw;
    height: 100vh;
    /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--availableViewportHeight) * 100);
    position: absolute;
    top: 0;

  }
  
  .phone-verification-page,
  .login-with-email-page,
  .login-error-page{
    form {
      width: 100%;
      @include displayFlex(flex-start, center, column);
    }
  }

  .content-frame-wrapper {
    margin-top: 0px !important;
    height: 87%;
  }

  .image-container {
    margin-top: 40px;
    margin-bottom: convertPxToVhVw(20, 'vh', 758);
    max-width: 220px;
    height: convertPxToVhVw(151, 'vh', 758);
    max-height: 151px;
    @include displayFlex(center, center);

    img {
      max-width: 115px;
      max-height: 130px;
    }
  }
  .auth-screen {
    .auth-title {
      margin-top: 0px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .auth-layout {
    // make keyboard open over the page without shrink it
    &:has(input[type='text']:focus),
    &:has(input[type='number']:focus),
    &:has(input[type='tel']:focus),
    &:has(input[type='email']:focus),
    &:has(textarea:focus) {
      overflow-y: scroll;
      height: 100vh;

      .on-boarding-layout {
        height: 100vh !important;

        > * {
          height: 100vh !important;
          padding-top: 30px;
          position: unset;
        }
      }

      .content-frame-wrapper {
        margin-top: 0px;
        height: 87vh;
        overflow: unset;
      }
    }

    .back-link {
      @include fontFootnote;
      position: fixed;
      top: 2px;
      left: 0;
    }

    .auth-screen {
      padding: 30px 20px 3vh;

      .auth-form {
        padding: 0 25px;
      }

      .content-frame-wrapper {
        padding-top: $authLayoutFrameWrapperPaddingMobile !important;
        padding-bottom: $authLayoutFrameWrapperPaddingMobile !important;
      }

      .auth-title {
        font-size: 24px !important;
        font-weight: 500 !important;
        letter-spacing: 0.24px !important;
      }

      &.shorten-onboarding-flow {
        .content-frame-wrapper {
          padding-left: 35px !important;
          padding-right: 35px !important;
        }
      }
      .--mobile {
        display: block !important;
      }

      .--desktop {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .auth-layout .auth-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 7vh 29% 5vh;
    position: relative;

    .back-link {
      @include fontBody;
      margin-top: 0vh;
    }

    .content-frame-wrapper {
      padding-top: 10vh !important;
    }
    .auth-title {
      font-size: 36px !important;
      font-weight: 500 !important;
      letter-spacing: 0.36px !important;
    }

    .--mobile {
      display: none !important;
    }
    .--desktop {
      display: block !important;
    }
  }
}

// small laptops bellow height 800px
@media only screen and (min-width: 769px) and (max-height: 800px) {
  .auth-layout .auth-screen {
    .content-frame-wrapper {
      padding-top: 5vh;
    }
  }
}
