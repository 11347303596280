@import '../../../styles/mixin.scss';

.plan-scroll-container {
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;

  > * {
    height: 100%;
    width: 100%;
    flex: 1 0 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
}

@include desktopView {
  .plan-scroll-container {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
  }
}

@include mobileView {
  .plan-scroll-container {
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
  }
  .plan-container-horizontal.plan-container-horizontal {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
  }
}
