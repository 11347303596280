@import './colors.scss';
@import './layout.scss';

// overwrites per page

// in chat task list
.in-chat-tasks-list-container {
  .tasks-list-item {
    height: 30px;
    @include themed(border, none);
    @include themed(box-shadow, none);
  }
  .tasks-list-item .task-item-left label.task-complete-label {
    width: 8px;
    height: 8px;
  }
  .tasks-list-item .task-item-left label.task-complete-label::before,
  .tasks-list-item .task-item-left label.task-complete-label::after {
    height: 8px;
    width: 8px;
    background-size: 6px;
  }

  @include desktopView {
    .tasks-list-item .task-item-left span.task-name {
      font-size: 12px;
    }
  }

  @include mobileView {
    .tasks-list-item .task-item-left label.task-complete-label {
      min-height: unset;
      padding: 0;
      padding-right: 10px;
    }
  }
}

// my plan
.plan-day-card-content {
  .tasks-list-item {
    margin-bottom: 0px;
  }

  .single-task-container {
    .swipeable-task-item {
      margin-bottom: 0px;
    }
  }

  &--due-tasks {
    .single-task-container {
      &:not(:last-child) {
        margin-bottom: 5px;
      }

      .tasks-list-item {
        .task-icons-container {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &--scheduled-tasks {
    $scheduleTaskCheckboxSize: 14px;
    .swipeable-task-item {
      @include themed(border, none);
    }
    .single-task-container {
      margin-bottom: 16px;

      .tasks-list-item {
        height: $singleTaskHightStageTasks;
        @include themed(border, none);
        @include themed(background-color, backgroundBrandLowContrast);
        border-radius: 8px;
        padding-right: 14px;
        padding-left: 14px;
        
        .task-item-left {
          max-width: 65%;

          label.task-complete-label {
            padding-left: calc($scheduleTaskCheckboxSize + 6px);
            &::after,&::before {
              @include updateCheckboxSize($scheduleTaskCheckboxSize);
            }
          }
          .task-name-container {
            span.task-name, span.external-event-source {
              margin-left: 8px;
            }
          }
        }

        &--event {
          .external-event-source {
            font-size: 10px;
          }

          .task-item-right {
            align-items: center;

            .task-icons-container {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

@include mobileView {
  // my plan
  .plan-day-card-content {
    &--scheduled-tasks{
      .single-task-container .tasks-list-item {
        .task-item-left {
          max-width: 45%;
        }
      }
    }
  }
}