@use 'sass:string';
@import './colors.scss';
@import './layout.scss';
@import './typography.scss';
@import './themes/theme-mixins.scss';

@mixin displayFlex($justify: flex-start, $align: flex-start, $direction: row, $wrap: nowrap) {
  display: flex;
  flex-wrap: $wrap;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin beforeAfterBackgroundTransition($beforeBgColor, $afterBgColor, $transitionDuration: 0.5s) {
  &::before,
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: opacity $transitionDuration linear;
    opacity: 0;
  }

  &::before {
    background: $beforeBgColor;
  }

  &::after {
    background: $afterBgColor;
  }
}

@function convertPxToVhVw($pxToConvert, $widthOrHeight, $viewPortHeightInPx: 940) {
  $viewPortWidthInPx: 1440;

  @if $widthOrHeight == 'vw' {
    @return calc($pxToConvert / $viewPortWidthInPx * 100vw);
  }

  @return calc($pxToConvert / $viewPortHeightInPx * 100vh);
}

@mixin separationLine($colorVarName: backgroundSecondary, $width: 100%, $height: 0.5px) {
  width: $width;
  margin: auto;
  @include themed(border-bottom, $colorVarName, $height solid );
}

@mixin boxShadow() {
  @include themed(border, borderPrimary, 1px solid);
  @include themed(box-shadow, borderPrimary, 0px 4px 0px 0px);
}

@mixin designedScrollbar(
  $topDistance: $scrollbarTopDistance,
  $bottomDistance: $scrollbarBottomDistance
) {
  &::-webkit-scrollbar-button:start:decrement {
    height: $topDistance;
    display: block;
    @include themed(background-color, backgroundTransparent);
  }

  &::-webkit-scrollbar-button:end:increment {
    height: $bottomDistance;
    display: block;
    @include themed(background-color, backgroundTransparent);
  }
}

@mixin fontSize($fontSize) {
  font-size: $fontSize;
}

@mixin fontWeight($fontWeight: normal) {
  font-weight: $fontWeight;
}

@mixin letterSpacing($letterSpacing: normal) {
  letter-spacing: $letterSpacing;
}

@mixin lineHeight($lineHeight: normal) {
  line-height: $lineHeight;
}

@mixin fontStyle($fontStyle: normal) {
  font-style: $fontStyle;
}

@mixin textTypography(
  $fontSize,
  $fontWeight: normal,
  $letterSpacing: normal,
  $lineHeight: normal,
  $fontStyle: normal
) {
  @include fontSize($fontSize);
  @include fontWeight($fontWeight);
  @include letterSpacing($letterSpacing);
  @include lineHeight($lineHeight);
  @include fontStyle($fontStyle);
}

@mixin position($position: absolute, $top: auto, $left: auto, $right: auto, $bottom: auto) {
  position: $position;
  top: $top;
  left: $left;
  bottom: $bottom;
  right: $right;
}

@mixin mobileView {
  @media only screen and (max-width: $mobileScreenMaxWidth) {
    @content;
  }
}

@mixin TabletsAndIPadsView {
  @media only screen and (min-width: $tabletsAndIpadsScreenMinWidth) and (max-width: $tabletsAndIpadsScreenMaxWidth) {
    @content;
  }
}

@mixin desktopView {
  @media only screen and (min-width: $desktopScreenMinWidth) {
    @content;
  }
}

@mixin transparencyBtn {
  border: none;
  @include themed(background-color, backgroundTransparent);
  padding: 0;
  display: flex;
}

@mixin stageExpandAndCollapseButton {
  @include transparencyBtn;
  display: block;
  position: absolute;
  top: $stageExpandAndCollapseTopLeftDistance;
  left: $stageExpandAndCollapseTopLeftDistance;
}

@mixin headerContainerDesktop {
  height: $desktopHeaderHeight;
  padding: 0 $desktopHeaderInlinePadding;
  padding-top: 40px;
}

@mixin responsiveFontSize($min, $max, $fallback: 16px) {
  font-size: $fallback !important;
  font-size: clamp($min, 1vw, $max) !important;
}

@mixin getOtpCountrySelectorMobile($countrySelectWidth,$gapBetweenCountrySelectAndInput) {
  .app-phone-controllers-container {
    width: 100%;
    justify-content: space-between;
    padding: 0;
    column-gap: $gapBetweenCountrySelectAndInput;

    &:not(:has(.app-phone-input)) {
      margin-bottom: 60px !important;
      .app-phone-select-controller {
        transform: unset;
        left: unset;
      }
    }

    .app-phone-select-controller {
      min-width: $countrySelectWidth;
      max-width: $countrySelectWidth;
      position: relative;
      transform: unset;
      left: unset;
    }

    .app-select-container--open {
      position: absolute;
      top: 0;
      left: 32px;
    }
  }
}

@mixin updateCheckboxSize($checkboxSize) {
  height: $checkboxSize;
  max-height: $checkboxSize;
  width: $checkboxSize;
  max-width: $checkboxSize;
}

@mixin appCheckboxUsingBeforeAndAfter($checkboxColor, $checkboxSize, $checkMarkSize: 10px) {
  &::before {
    content: '';
    @include themed(background-color, backgroundTransparent);
  }

  &::after {
    content: none;
    background-image: url('../assets/images/task-checkmark.svg');
    background-position: center;
    background-position-x: 55%;
    background-position-y: 55%;
    background-size: $checkMarkSize;
    background-repeat: no-repeat;
    background-color: $checkboxColor;
    @include displayFlex(center, center);
  }

  &::before,
  &::after {
    height: $checkboxSize;
    max-height: $checkboxSize;
    width: $checkboxSize;
    max-width: $checkboxSize;
    position: absolute;
    border: 3px solid $checkboxColor;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &:has(input[type='checkbox']:checked) {
    &::after {
      content: '';
    }
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    left: -9999px;
  }
}

@mixin appGradientBorderForInputElement {
  border: 1px solid transparent;
  background-origin: border-box; /* Gradient starts at border */
  background-clip: padding-box, border-box; 
  @include themedGradientBorderByBgImg(backgroundPrimary, gradientInput);
  @include themed(background-color, backgroundTransparent);
}

@mixin appScrollbar {
  /* width */
  ::-webkit-scrollbar {
    width: $desktopAppScrollbarWidth;
    height: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 50px;
    opacity: 0.5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @include AppScrollbarThumbColor;
    border-radius: 50px;
  }
}

@mixin fullCircle($size, $bgColor) {
  @include displayFlex(center, center);
  width: $size;
  min-width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $bgColor;
}

@mixin AppScrollbarThumbColor {
  @include themed(background-color, backgroundBrandMediumContrast);
}

@mixin multiLineEllipsis($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}