@import '../StageContainer.scss';
@import '../../../../../styles/themes/theme-mixins.scss';
@import '../stage-planner/StagePlannerVariables.scss';

.stage-header {
  display: none;
}

@include desktopView {
  .stage-header {
    width: 100%;
    display: block;
    height: $desktopHeaderHeight;
    @include themed(background-color, backgroundTransparent);
    text-align: start;
    @include displayFlex(flex-start, flex-start, column);
    padding: 36px $stageContentPaddingRightDesktop 0 $stageContentPaddingLeftDesktop;
    position: relative;

    .expand,
    .minimize-stage {
      @include stageExpandAndCollapseButton;
    }

    .stage-header-content {
      width: 100%;
      padding-bottom: 13px;
      @include displayFlex(space-between, flex-end);
      position: relative;
    }

    &--tasks,
    &--timer {
      justify-content: space-between;

      .stage-header-content {
        align-items: center;

        .header-title {
          @include fontLargeTitle(-0.32px);
          @include themed(color, textBrand);
          white-space: nowrap;
        }
      }
    }

    &--plan {
      padding-top: 39px;
    }
  }

  .display-stage.stage-expanded .stage-header {
    align-items: center;

    &--plan {
      padding-left: $stagePlanContainerPaddingRightLeftOnExpendMode;
      padding-right: $stagePlanContainerPaddingRightLeftOnExpendMode;
    }

    &--tasks {
      padding-left: $stageContentPaddingLeftDesktop;
      padding-right: $stageContentPaddingLeftDesktop;

      .stage-header-content {
        max-width: $stageContentMaxWidthOnExpendMode;
      }
    }
  }

  body{
    &[data-planner-state='unscheduledtasksplacer'] {
      .stage-header {
        padding-right: $plannerWidthWhenOpen;
      }
    }
  }
}
