@import '../../../styles/sharedStyle.index.scss';

// shared mobile and desktop-expand mode
@mixin mobileModeStaging {
  .chat-container {
    .chat {
      .conversation {
        display: none;
      }

      .stage-container {
        width: 100% !important;
        display: flex !important;
        flex: 1;
        overflow-y: auto;
      }
    }
  }
}

// shared mobile and desktop
.chat-container {
  z-index: 1;
  @include displayFlex(center, center);

  &.hide {
    opacity: 0;
  }

  .chat {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    &:has(.page-loader-animation) {
      .page-loader-animation {
        flex: 1;
        margin: 0 auto;
      }
    }
  }
}

// mobile style
@include mobileView {
  .chat-container {
    width: 100vw;
    height: 100%;
    @include themed(background-color, backgroundPrimary);

    .chat-frame {
      width: 100%;
      max-width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      border-radius: 0;
      text-align: unset;
      box-shadow: none;
      border: none;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .chat {
      flex: 1;
      height: 100%;
      margin-top: $mobileHeaderHeight;
      height: calc(var(--availableViewportHeight) * 100 - $mobileHeaderHeight);
      padding-bottom: calc($mobileChatFormInputHeight + $mobileNavbarHeight + $mobileChatBarFormBottomSpace);

      &:has(.dislike-feedback-container) {
        padding-bottom: 0;
      }
    }

    .debug-user-card {
      display: none;
    }
  }

  .display-stage {
    @include mobileModeStaging;
  }
}

// desktop style
@include desktopView {
  .chat-container {
    overflow-y: auto;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: visible;

    .chat-frame {
      height: 100%;
      width: $desktopChatBoxWidth;
    }

    .chat {
      height: calc(100% - $desktopHeaderHeight);

      .stage-container {
        display: none !important;
      }

      .content-frame-wrapper {
        @include themed(background-color, backgroundTransparent);
      }
    }

    .debug-user-card {
      position: absolute;
      border-radius: 8px;
      @include themed(box-shadow, borderPrimary, 0px 2px 0px 1px);
      @include themed(background-color, backgroundPrimary);
      @include themed(background-color, backgroundPrimary);
      @include themed(color, textPrimary);
      z-index: 99999;
      top: 10px;
      left: 10px;
      padding: 10px;
      max-width: 250px;
    }
  }
}
