@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';
@import '../../../../styles/layout.scss';

$settingScrollbarMarginRight: 50px;

.settings-menu {
  width: 100%;
  height: calc(100vw - $mobileHeaderHeight);
  height: calc(var(--availableViewportHeight) * 100 - $mobileHeaderHeight);
  text-align: left;
  position: absolute;
  top: auto;
  left: 0;
  z-index: $settingsOverlayZIndex;
  letter-spacing: 0.01em;
  @include themed(color, textPrimary);
  @include themed(background-color, backgroundPrimary);

  .scrollable-container {
    width: 100%;
    height: 100%;
    padding: 30px 22px 60px;
    overflow: hidden;
    overflow-y: auto;
    max-width: 531px;
    margin: auto;
  }

  .discord-link {
    margin-bottom: 24px;
    line-height: 19px;
    @include fontFootnote;
  }

  .separation {
    @include separationLine();
    @include themed(border-bottom-color, borderPrimary);
    margin: 10px 0 20px 0;
  }

  h2.sub-title {
    @include fontBody;
    line-height: 24px;
    @include themed(color, textBrand);
  }

  .reset-profile-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@include mobileView {
  .settings-menu {
    button.close-settings {
      display: none;
    }
  }
}

@include desktopView {
  .settings-menu {
    height: calc(100% - $desktopHeaderHeight);

    button.close-settings {
      position: absolute;
      top: 20px;
      left: 20px;
      padding: 0;
      @include themed(border, none);
      @include themed(background-color, backgroundTransparent);
      z-index: 99;
    }

    .scrollable-container {
      width: calc(100% - $settingScrollbarMarginRight);
      padding: 60px 41px 41px 44px;
      @include designedScrollbar();
      position: relative;

      &::-webkit-scrollbar-button:start:decrement {
        height: 150px;
      }
      &::-webkit-scrollbar-button:end:increment {
        height: 26px;
      }
    }
  }
}
