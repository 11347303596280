@import "./theme-generator.module.scss"; // Ensure $themes & $themeValues are available globally

/* Mixin for applying theme styles on element selectors
   Usage Examples: 
   1. @include themed(background-color, themeColorKeyName);
   2. @include themed(border, themeColorKeyName, 1px solid); (with inlineExtra)
   3. @include themed(color, themeColorKeyName, null, !important); (with postExtra)
*/
@mixin themed($property, $key, $inlineExtra:null, $postExtra:null) {
    @each $theme in $themes {
    @if (index((none, transparent, initial, inherit, unset), $key)) {
      @at-root [data-theme=#{$theme}] #{if(&, "&", "")} {
        #{$property}: #{$inlineExtra} #{$key} #{$postExtra};
      }
    }
    @else if (themeKeyExists($key, $theme)) {
      $value: getThemeValue($key, $theme);
      
      // Apply theme styles at root level with [data-theme=#{$theme}].
      // Set #{$property} to CSS variable with fallback #{$value}.
      // Include optional #{$inlineExtra} and #{$postExtra}.
      // @at-root [data-theme=#{$theme}] #{if(&, "&", "")} {
      //   #{$property}: #{$inlineExtra} var(--theme-#{$theme}-#{$key}, #{$value}) #{$postExtra};
      // }
      // #{if(&, "&", "")} - ensures you only add & (the parent selector) if it exists—otherwise, you won’t get a dangling comma in the compiled CSS.
      @at-root [data-theme="#{$theme}"] #{if(&, "&", "")} {
        #{$property}: #{$inlineExtra} var(--theme-#{$theme}-#{$key}, #{$value}) #{$postExtra};
      }
    } @else {
      @error "Property #{$key} doesn't exist in the #{$theme} theme";
    }
  }
}

// set background image that get gradient border on the element
@mixin themedGradientBorderByBgImg($solidKey, $gradientKey) {
  @each $theme in $themes {
    @if (themeKeyExists($solidKey, $theme) and themeKeyExists($gradientKey, $theme)) {
      $solidValue: getThemeValue($solidKey, $theme);
      $gradientValue: getThemeValue($gradientKey, $theme);
      
      // Apply theme styles at root level with [data-theme=#{$theme}].
      @at-root [data-theme=#{$theme}] #{if(&, "&", "")} {
        background-image: linear-gradient(var(--theme-#{$theme}-#{$solidKey}, #{$solidValue}), var(--theme-#{$theme}-#{$solidKey}, #{$solidValue})), var(--theme-#{$theme}-#{$gradientKey}, #{$gradientValue});
      }
    } @else {
      @error "Properties #{$solidKey} and #{$gradientKey} don't exists in the #{$theme} theme";
    }
  }
}