@import '../../../styles/colors.scss';
@import '../../../styles/mixin.scss';

$countrySelectWidth: 72px;
$gapBetweenCountrySelectAndInput: 8px;

.app-phone-controllers-container {
  padding: 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 300px;
  margin-bottom: 30px;

  &:not(:has(.app-phone-input)) {
    margin-bottom: 111px;

    .app-phone-select-controller {
      transform: translateX(-50%);
      left: 50%;
    }
  }

  .error {
    max-width: 100%;
    bottom: -35px;
    left: 80px;
  }

  .app-phone-select-controller {
    height: 40px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: $countrySelectWidth;
    z-index: 9999;

    .app-select-element {
      padding: 0 22px 0 8px;
      height: 100%;
    }

    .app-select-arrow {
      transform: translateY(-40%);
      top: 50%;
      right: 5px;
    }
  }

  .flag-img {
    height: 12px;
    margin-right: 4px;
  }

  .app-select-container--open {
    max-width: 250px;
    width: 220px;

    .app-custom-option {
      text-align: start;
      overflow-x: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;

      div.img-wrapper {
        width: 145px;
        display: flex;
        justify-content: flex-start;

        img.flag-img {
          margin-top: 4px;
          margin-right: 6px;
        }
      }
    }
  }
}

@include mobileView {
  @include getOtpCountrySelectorMobile($countrySelectWidth, $gapBetweenCountrySelectAndInput);
}

@media only screen and (max-width: 330px) {
  .app-phone-controllers-container .app-phone-input {
    width: calc(100% - $countrySelectWidth - $gapBetweenCountrySelectAndInput);
  }
}