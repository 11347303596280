@import '../../../styles/sharedStyle.index.scss';
@import '../../chat-wrapper/resizable-container/stage-container/stage-planner/StagePlannerVariables.scss';

.create-plus-btn {
  @include transparencyBtn();
  position: absolute;
  cursor: pointer;
  z-index: $ZIndex999;
  transition: 0.3s;
  .plus-fab  {
    * {
      @include themed(fill, fabsPrimary);
    }
    path{
      @include themed(stroke, textContrast);
    }
  }
}

body {
  &:has(.planner-plus-overlay)  {
    .create-plus-btn {
      transform: rotate(45deg);
      z-index: $settingsOverlayZIndex;
    }
  }
  &:has(.work-block-dialog-container)  {
    .create-plus-btn {
      z-index: $ZIndex99;
    }
  }
}

@include mobileView {
  .create-plus-btn {
    right: 15px;
    bottom: 12px;
  }
}

@include desktopView {
  .create-plus-btn {
    right: calc($stageContentPaddingRightDesktop);
    bottom: calc($chatBarMarginBottom - 1px);
  }

  .display-stage.stage-expanded .create-plus-btn {
    right: $stagePlanContainerPaddingRightLeftOnExpendMode;
    bottom: calc($chatBarMarginBottom - 1px);
    opacity: 1;
    transition: all $plannerMenuTransitionTime ease;
  }

  // when the planner is open
  body[data-planner-state="unscheduledtasksplacer"]{
    .display-stage.stage-expanded .create-plus-btn,
    .create-plus-btn {
      right: $plannerWidthWhenOpen;
      opacity: 0;
      pointer-events: none;
    }
  }

  body:has(.details-dialog-animation-container) {
    .display-stage.stage-expanded .create-plus-btn{
      display: none;
    }
  }
}
