@import '../../../../../styles/colors.scss';
@import '../../../../../styles/themes/theme-mixins.scss';

.options-container {
  padding-top: 10px;
  margin-bottom: -10px;
  width: 100%;

  .option {
    .msg-content {
      width: fit-content;
      max-width: 354px;
      box-shadow: none;
      padding: 10px 25px;
      @include themed(background-color, backgroundBrandMediumContrast);
      @include themed(color, textPrimary);
      @include themed(border, none);
      border-radius: 50px;
      
      &:disabled {
        opacity: 0.7;
      }
    }
    
    &.selected .msg-content {
      @include themed(background-color, backgroundBrandContrast);
      opacity: 1;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}

@media only screen and (min-width: 769px) {
  .options-container {
    padding-top: 20px;
    .option {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0px;
      }
    }
  }
}
