@import '../../../../../../../styles/sharedStyle.index.scss';

.task-work-block-overlay {
  width: 100%;

  .assigned-to-title {
    @include fontCaption;
    @include themed(color, textTertiary);
    margin-bottom: 12px;
  }

  .task-work-block-details {
    @include fontBody;
    @include themed(color, textPrimary);
    @include displayFlex(flex-start, center);

    &:first-of-type {
      margin-bottom: 12px;
    }

    svg {
      margin-right: 5px;

      path {
        @include themed(stroke, textPrimary);
      }
    }
  }
}
