@import '../../../../styles/colors.scss';
@import '../../../../styles/mixin.scss';
@import '../../../../styles/themes/theme-mixins.scss';

$marginBetweenMessages: 8px;

.message {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: $marginBetweenMessages;

  &.form-fields:last-child {
    margin-bottom: 0;
  }

  .message-content-container {
    width: fit-content;
    max-width: 85%;
  }

  .msg-content {
    height: auto;
    width: fit-content;
    width: 100%;
    border-radius: 8px;
    text-align: left;
    // sequences of whitespace will collapse into a single whitespace. Text will wrap when necessary, and on line breaks
    white-space: pre-line;
    // will only create a break if an entire word cannot be placed on its own line without overflowing.
    overflow-wrap: break-word;
    padding: 12px 20px;
    @include themed(color, textInvert);
    @include themed(background-color, backgroundBrandContrastAlt);
    @include themed(border, transparent, 1px solid);

    &,
    * {
      @include fontBody;
      line-height: 24px;
    }
  }

  &.user,
  &.option {
    flex-direction: row-reverse;
  }

  &.user .message-content-container {
    @include displayFlex(normal, flex-end, column);

    .msg-content {
      @include themed(color, textPrimary);
      @include themed(background-color, backgroundBrandLowContrast);
      @include themed(border-color, borderPrimary);
    }
  }

  &.bot, &.create-session-error {
    .msg-content {
      @include themed(border-color, borderBrandMediumContrast);
    }
  }

  &.debug-message {
    .msg-content {
      background-color: $debug-function-msg-color;
    }
  }

  &.reject-error {
    margin-top: $marginBetweenMessages;

    .msg-content {
      @include themed(border, none);
    }
  }

  // relative to the chat-conversation container
  &.create-session-error {
    position: absolute;
    bottom: 20px;
    left: auto;
    width: auto;

    .msg-content {

      button.try-again-create-session {
        padding: 0;
        @include themed(border, none);
        @include themed(background-color, backgroundTransparent);
        @include themed(color, textInvert);
        text-decoration: underline;
      }
    }
  }
}

@include desktopView {
  .message {
    margin-bottom: $marginBetweenMessages;

    .message-content-container {
      width: fit-content;
      max-width: 75%;
    }

    &.reject-error {
      margin-top: $marginBetweenMessages;
    }
  }
}