@import '../../../styles/sharedStyle.index.scss';

.link-accounts-page {
  .link-accounts-content-container {
    height: 100%;
    @include displayFlex(flex-start, center, column);
    overflow-y: auto;

    .link-accounts-description {
      max-width: 300px;
      margin: 0 auto;
      text-align: center;
      line-height: 1.5;
      @include fontTitle2;
      margin-bottom: 24px;
    }
  }

  &--view-type-2 {
    .link-accounts-content-container {
      row-gap: 0;
    }
  }

  .back-link {
    position: unset;
    width: fit-content;
    margin-top: 24px;
  }
}

@media only screen and (max-width: 768px) {
  .link-accounts-page {
    .link-accounts-description {
      max-width: 300px;
    }
  }
}

@media only screen and (min-width: 769px) {
  .link-accounts-page {
    .link-accounts-description {
      max-width: 500px;
    }
  }
}
