@import '../../../../../styles/mixin.scss';
@import '../../../../../styles/colors.scss';

.summaries-group {
  letter-spacing: 1px;

  .single-summary {
    @include themed(border-bottom, borderSecondary, 1px solid);
    padding: 12px 0;
  }

  .single-summary {
    &:first-of-type {
      @include themed(border-top, borderSecondary, 1px solid);
    }
    &:last-of-type {
      margin-bottom: 0;
      @include themed(border-bottom, borderSecondary, 1px solid);
    }
  }

  .group-title {
    @include themed(color, textTertiary);
    @include fontCaption;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 20px;
  }
}

// mobile
@include mobileView {
  .summaries-group {
    .single-summary {
      @include themed(border-top, none, null, !important);
    }
    .single-summary:first-of-type {
      padding-top: 0 !important;
    }
  }
}
