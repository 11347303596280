@import '../../../../styles/sharedStyle.index.scss';

.plan-day-scheduled-work-block {
  width: 100%;
  min-height: 90px;
  @include themed(background-color, backgroundBrandLowContrast);
  cursor: pointer;
  margin: 10px 0;
  padding: 16px 4px;
  border-radius: 5px;

  &--header {
    @include displayFlex(space-between, center);
    width: 100%;
    padding-left: 14px;
    padding-right: 10px;
    padding-bottom: 9px;

    .workBlock-title {
      @include fontFootnote;
      @include themed(color, textPrimary);

      .work-block-icon {
        margin-right: 13px;
      }
    }

    .pen-and-time-range {
      @include displayFlex(flex-end, center);
      column-gap: 11px;

      .work-block-time-range {
        @include fontFootnote;
        @include themed(color, textPrimary);      }
    }
  }

  &--tasks {
    @include displayFlex(flex-start, flex-start, column);
    row-gap: 16px;
    margin-top: 16px;
    width: 100%;

    .single-task-container {
      margin-bottom: 0px !important;
      .tasks-list-item {
        @include themed(background-color, backgroundBrandLowContrast);
        height: 24px !important;
        .task-item-left{
          .quick-win-icon-container {
            display: none;
          }
        }
        .task-item-right {
          .task-icons-container {
            display: none !important;
          }
        }
      }
    }
  }
}

@include mobileView {
  .plan-day-scheduled-work-block {
    padding: 16px 0px;
    &--header {
      padding-left: 18px;
      padding-right: 14px;
    }
  }
}

@include desktopView {
  .plan-day-card-content--scheduled-tasks .plan-day-scheduled-work-block{
    &--tasks {
      .single-task-container .tasks-list-item {
        .task-item-left {
          .task-name-container {
            span.task-name, span.external-event-source {
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}