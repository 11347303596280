@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Dosis:wght@200..800&family=Dosis:wght@200;300;400;500;600;700;800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@import './styles/themes/theme-generator.module.scss';
@import './styles/layout.scss';
@import './styles/colors.scss';
@import './styles/mixin.scss';
@import './styles/animations.scss';
@import './styles/overrideStyleWhenKeyboardOpen.scss';
@import './styles/data-attributes-controls.scss';
@import './styles/tasks.scss';
@import './styles/typography.scss';
@import './styles/themes/theme-mixins.scss';

:root {
  @include generateThemeVariables;
}


// basic css reset
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  scroll-behavior: smooth;
}

#root {
  height: 100%;
}

body {
  font-family: 'Lato', 'Dosis', 'Caprasimo', sans-serif !important;
  height: calc(var(--availableViewportHeight) * 100);

  * {
    box-sizing: border-box;
  }
}

* {
  font-family: 'Lato', 'Dosis', 'Caprasimo', sans-serif !important;
}

h1 {
  font-family: 'Dosis' !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

button,
.app-error {
  font-family: 'Lato' !important;
}

button,
a {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0px;
}

.scroll-y-container-hidden-scroll-bar {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.scroll-y-container-hidden-scroll-bar::-webkit-scrollbar {
  /* WebKit */
  width: 0px;
  /*Chrome, Safari, Edge*/
  display: none;
}

.antd-video-modal {
  width: 100vw !important;

  svg {
    @include themed(background-color, backgroundPrimary);
  }
}

// All the static strings will be with font-family:'Dosis'
.static-string {
  font-family: 'Dosis' !important;
}

// All the strings that comes from the user-from the server will be with font-family:'Lato'
.user-detail {
 @include fontSmallTitle(.2px, 400);
}

.neutral-4-bg {
  @include themed(background-color, backgroundPrimary, null, !important);
}

.hidden-select-element {
  position: absolute;
  z-index: -1;
}

.no-style-button {
  @include themed(background-color, backgroundTransparent);
  @include themed(color, inherit);
  @include themed(border, none);
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input, textarea{
  &::placeholder {
    @include themed(color, textPlaceholder);
  }
}


@media only screen and (min-width: $desktopScreenMinWidth) {
  @include appScrollbar;

  .display-scrollbar-only-on-hover {

    &::-webkit-scrollbar-thumb {
      @include themed(background-color, backgroundTransparent);
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        @include AppScrollbarThumbColor;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  // fix iOS keyboard spacing bug: https://gist.github.com/kiding/72721a0553fa93198ae2bb6eefaa3299
  textarea,
  input {
    &:focus {
      animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.02s !important;
    }
  }
}

@keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@include mobileView {
  body {
    position: fixed;
    bottom: var(--offset-h, 0);
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: calc(var(--availableViewportHeight) * 100);
    overflow: hidden;
  }
}

button,
input,
textarea {
  -webkit-tap-highlight-color: transparent !important;
  -webkit-user-select: none !important;
  user-select: none !important;
}

.disable-horizontal-scroll {
  overflow-x: hidden !important;
}

.clickable-element {
  cursor: pointer;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

// handle landscape mode for my-week plan
// @media (orientation: landscape) {
//     body[data-plan="my-week"][data-orientation="landscape"] {
//        .app-main:not(:has(.stage-expanded)) {
//            .navbar-container, .stage-header,.chat-container {
//                display: none !important;
//            }
//            .flex-chat-stage-container {
//                padding: 0 !important;
//                border-radius: 0 !important;
//            }
//            .chat-container {
//                display: none !important;
//            }
//            .plan-day-card-container {
//                padding: 0 !important;
//            }
//            .content-frame-wrapper  {
//                border-radius: unset !important;
//            }
//        }
//         .app-main:has(.stage-expanded) {
//             .navbar-container {
//                 display: none !important;
//             }
//             .flex-chat-stage-container {
//                 padding: 0 !important;
//             }
//             .header-container {
//                 display: none !important;
//             }
//             #chat-form {
//                 display: none !important;
//             }
//             .plan-day-card-container {
//                 padding: 0 !important;
//             }
//             .content-frame-wrapper .chat-frame {
//                 height: 100% !important;
//             }
//             #chat-main {
//                 height: 100% !important;
//             }
//         }
//         .plan-day-weeks-header-container {
//             display: none;
//         }
//     }
// }

body[data-planner-state='timepicker'] {
  .content-frame-wrapper {
    .stage-planner-container:not(:last-of-type),
    .stage-tasks-container:first-of-type {
      display: none;
    }
  }
  .resizable-container.display-stage.stage-expanded .chat-container .chat-frame {
    @include themed(background-color, backgroundTransparent, null, !important);
  }
  .plan-day-card-content--due-tasks {
    display: none !important;
  }
  .add-edit-task-container {
    visibility: hidden !important;
    &--subtask {
      height: 0 !important;
      display: none !important;
    }
    &--header {
      display: none !important;
    }
  }
  .subtask-details-modal-container {
    @include themed(background-color, backgroundTransparent, null, !important);
  }
  .subtask-form-container {
    width: 100% !important;
    height: 100% !important;
  }
  .details-dialog-animation-container {
    @include themed(background-color, backgroundTransparent, null, !important);
  }
}

@include desktopView {
  body[data-planner-state='timepicker'] {
    &:has(.stage-container .details-dialog-animation-container.endSlideAnimation) {
      @include themed(background-color, backgroundTransparent, null, !important);
    }
  }
  body:not([data-planner-state='timepicker']) {
    .app-main .content-frame-wrapper {
      &:has(.details-dialog-animation-container.endSlideAnimation) {
        @include themed(background-color, backgroundPrimary);
      }
    }
  }
}

@include mobileView {
  body[data-planner-state='timepicker'] {
    .stage-planner-container {
      .stage-header,
      .stage-header-content {
        width: 100%;
      }
    }
    .stage-planner-container .stage-header {
      display: unset !important;
      margin-bottom: 10px;
      .expand {
        display: none !important;
      }
    }
    .content-frame-wrapper:has(.stage-container
        .details-dialog-animation-container.endSlideAnimation) {
      @include themed(background-color, backgroundTransparent, null, !important);
    }
    .subtask-details-modal-container {
      border: none !important;
    }
    .subtask-form-container {
      @include themed(background-color, backgroundTransparent, null, !important);
    }
    .app-overlay-popover-background-container:not(:has(.slideIn-full-screen-mobile)) {
      @include themed(background-color, backgroundTransparent, null, !important);
    }
    .app-overlay-popover-background-container .app-overlay-popover-content-container {
      bottom: $mobileChatFormInputHeight;
      border-bottom: none;
      border-radius: 8px;
      z-index: 1;
    }
  }
  body[data-planner-state='unscheduledtasksplacer'] {
    .create-plus-btn {
      display: none !important;
    }
    &:has(.plan-tasks-placement-list-container--mobile.plan-tasks-placement-list-container--placing) {
      .plan-day-card-content--calendar-day {
        padding-bottom: calc($overlayMobileHeightWhenPlacingTask - 10px);
      }
    }
  }
}
