@import '../../../../../../../styles/sharedStyle.index.scss';

@mixin createOrEditSubtaskPageMobileAndDesktop {
  $paddingRightLeft: 20px;
  $headerTopPosition: 40px;
  $headerHeight: 33px;

  .add-edit-task-container {
    padding: 0;
    padding-top: calc($headerTopPosition + $headerHeight);

    .add-edit-task-container--header {
      align-items: center;
      padding: 0 20px 0 18px;
      top: $headerTopPosition;
      height: $headerHeight;

      &--title {
        margin-left: 16px;
      }
    }

    form.add-edit-task-form {
      padding: $paddingRightLeft;
      padding-top: 40px;
      justify-content: flex-start;

      .name-controller {
        margin-bottom: 12px;

        input.name-text {
          @include textTypography(16px, 400, 0.16px);
        }
      }

      .task-tags-container {
        .create-or-edit-sub-title {
          margin-bottom: 10px;
        }
      }

      button.delete-item {
        height: 40px;
        width: calc(100% - $paddingRightLeft * 2);
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        @include themed(background-color, backgroundBrandPrimary);
      }
    }
  }
}

.subtask-details-modal-container {
  .add-edit-task-container {
    position: relative;
    height: 100%;
  }
}

@include mobileView {
  .subtask-details-modal-container {
    @include themed(background-color, backgroundPrimary);
    @include position(absolute, 0, 0, 0, 0);
    z-index: $summariesOverlayZIndex;
    @include displayFlex(flex-start, flex-start, column);
    height: 100%;
    overflow: hidden;

    .subtask-header {
      margin-bottom: 27px;
      text-align: start;
      width: 100%;

      .parent-name {
        @include textTypography(16px, 400, 0.16px);
      }

      .parent-description {
        @include textTypography(14px, 400, 0.14px);
        margin-bottom: 8px;
        margin-top: 2px;
      }

      .parent-tags {
        @include displayFlex(flex-start, center, row, wrap);
        row-gap: 3px;

        .single-tag {
          padding: 3px 5px;
          font-size: 6px;
          min-width: 30px;
          height: 15px;
          @include displayFlex(center, center);
          font-weight: 500;
          margin-right: 5px;
        }
      }
    }

    .subtask-form-container {
      position: relative;
      flex: 1;
      @include themed(background-color, backgroundPrimary);
      border-radius: 8px;
      overflow: auto;
      width: 100%;
    }

    .add-edit-task-container {
      margin-top: 0;
      @include themed(background-color, backgroundPrimary);
      box-shadow: none;
      @include themed(border, none);
      border-radius: 8px;
    }

    &:has(input:focus),
    &:has(textarea:focus) {
      position: absolute;
      overflow-y: scroll;

      .subtask-form-container {
        overflow: unset;
      }
    }

    @include createOrEditSubtaskPageMobileAndDesktop;
  }
}

@include desktopView {
  .subtask-details-modal-container {
    padding: 0;
    @include position(absolute, 0, 0, 0, 0);
    z-index: $summariesOverlayZIndex;
    @include themed(background-color, colorOverlay);
    height: 100%;
    width: 100%;
    @include displayFlex(flex-end, flex-end);
    padding: 0 10px;

    .subtask-form-container {
      position: relative;
      height: 77%;
      width: 500px;
      margin: auto;
    }

    .add-edit-task-container {
      @include themed(background-color, backgroundPrimary);
      border-radius: 8px;
      @include themed(box-shadow, borderPrimary, 0px 4px 0px 0px);
      overflow: auto;
    }

    @include createOrEditSubtaskPageMobileAndDesktop;
  }
}