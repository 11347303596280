@import '../../../../../../../styles/sharedStyle.index.scss';
@import '../CreateOrEditTask.mixins.scss';

@mixin reminderBellPosition {
  position: absolute;
  right: 0;
}

.scheduling-section {
  display: flex;
  flex-direction: column;
}

form.add-edit-task-form,
.work-block-form {
  .scheduling-section {
    @include displayFlex(flex-start, flex-start, column);
    column-gap: 6px;
    width: 100%;
    
    .scheduling-section-data-content-container,
    &.work-block-controller .scheduling-section-data-container--with-value {
      cursor: pointer;
      @include displayFlex(flex-start, center);

      & > * {
        display: inherit;
        align-items: center;
      }

      p {
        @include taskDetailsTextColor;
      }
    }

    &.work-block-controller .scheduling-section-data-container--with-value {
      padding: 0;
    }
    
    &:not(:has(.scheduling-section-add-data-action-container)){
      .task-details-sub-section.scheduling-section-data-content-container{
        &--work-time,
        &--work-block {
          padding-bottom: 0;
          margin-bottom: 0;
          @include themed(border, none);
        }
      }
    }

    .scheduling-section-add-data-action-container,
    &.work-block-controller
      .scheduling-section-data-container--without-value
      .scheduling-icon-and-label-container {
      @include displayFlex(flex-start, center);
      cursor: pointer;
      padding: 0;
      width: 100%;
      @include themed(border, none);
      p {
        @include themed(color, textSecondary);
      }
    }

    button,
    .scheduling-icon {
      cursor: pointer;
    }

    .bell-reminder {
      @include transparencyBtn;
      padding-top: 2px;
      
      .task-bell * {
          @include themed(stroke, textPrimary);
          @include themed(fill, none);
      }
    }

    &--read-only {
      .scheduling-section-add-data-action-container,
      .scheduling-section-data-content-container {
        pointer-events: none !important;
        cursor: alias !important;

        .bell-reminder {
          display: none;
        }
      }
    }
  }
}