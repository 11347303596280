// Ensure these variables exist globally and are only defined once
$themes: () !default;
$themeValues: () !default;

// Mixin to generate theme mappings only once
@mixin generateThemeMappings($themeName, $newThemeMap) {
  // Prevent duplicate theme registrations
  @if not index($themes, $themeName) {
    //creates/adds to list of theme names
    $themes: append($themes, $themeName, $separator: comma) !global;
  }
  
  @each $key, $value in $newThemeMap {
    // If key doesn't exist, add a new theme map
    @if not map-has-key($themeValues, $key) {
      $themeValues: map-merge($themeValues, ($key: ( $themeName: $value ))) !global;
    }
    // Adds to existing key map
    @else {
      $existingKeyMap: map-get($themeValues, $key);
      // If theme variable doesn't exist, add it
      @if not map-get($existingKeyMap, $themeName) {
        $newKeyMap: map-merge($existingKeyMap, ( $themeName: $value ));
        $themeValues: map-merge($themeValues, ( $key: $newKeyMap )) !global;
      }
    }
  }

  // Exports theme variables so they can be used in ts files
  :export {
    themes: $themes;
    @each $key, $value in $newThemeMap {
      theme_#{$themeName}_#{$key}: $value;
    }
  }
}

@mixin generateThemeVariables {
  @each $key, $val in $themeValues {
    @each $theme in $themes {
      @if (themeExists($key, $theme)) {
        --theme-#{$theme}-#{$key}: #{getThemeValue($key, $theme)};
      }
    }
  }
}

// Import theme data
@import './theme-light';
@include generateThemeMappings(light, $light);

@import './theme-dark';
@include generateThemeMappings(dark, $dark);