@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixin.scss';

.invite-friends-card-container {
  height: auto;
  border-radius: 10px;
  @include themed(border, borderPrimary, 1px solid);
  padding: 30px 20px 25px 20px;
  margin-bottom: 24px;

  .invite-friends-card-header {
    @include fontSmallTitle(-0.24px);
    @include themed(color, textBrand);
  }
  .invite-friends-mascot-image {
    width: 71px;
    height: 84px;
    * {
      @include themed(stroke, backgroundBrandPrimary); 
    }
  }
}
