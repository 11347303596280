// Switch
.app-toggle.ant-switch {
  @include themed(background-color, backgroundBrandMediumContrast, null, !important);
  width: 39.2px;
  height: 22.4px;
  min-height: 22.4px;
  min-width: 39.2px;

  .ant-switch-handle {
    width: 15px;
    height: 15px;
    min-height: 15px;
    min-width: 15px;
    left: 5px;
    top: 3px;

    &:before {
      @include themed(background-color, textInvert);
      border-radius: 50%;
      box-shadow: none;
    }
  }

  &.ant-switch-checked {
    &:focus {
      box-shadow: none;
    }

    .ant-switch-handle {
      left: calc(100% - 19px);
    }
  }

  // add any color you want to change the toggle color when checked
  &.app-toggle-active-dark {
    &.ant-switch-checked {
      @include themed(background, brandSecondary, null, !important);
    }
  }
}
